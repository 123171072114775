import React, { Component } from "react";
import { Tab } from "@mui/material";
import { Stack } from "@mui/system";
import { Box } from "@mui/system";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import HetPhepView from "./HetPhepView";
import QuaHenView from "./QuaHenView";
import NghiViecView from "./NghiViecView";
import DangNghiView from "./DangNghiView";

const MAX_PAGE_SIZE = 3;

class DSHetPhepPage extends Component {
  maxPage = 3;
  state = {
    permission: [],
    tabIndex: "4"
  };
  render() {
    return (
      <Stack spacing={0}>
        <TabContext value={this.state.tabIndex}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={(ev, tabIndex) => {
                this.setState({ tabIndex });
              }}
            >
              <Tab label="Danh sách đang nghỉ phép" value="4" />
              <Tab label="Danh sách nhân viên hết hạn nghỉ phép" value="1" />
              <Tab label="Danh sách nhân viên quá hạn hẹn lại" value="2" />
              <Tab label="Danh sách cần xác nhận thôi việc" value="3" />
            </TabList>
          </Box>
          <TabPanel value="4">
            { <DangNghiView
                    service={this.nhanVienService}
                    nhanVien={this.state.nhanVien}
                    onEditComplete={this.onEditComplete} /> }
          </TabPanel>
          <TabPanel value="1">
            { <HetPhepView
                    service={this.nhanVienService}
                    nhanVien={this.state.nhanVien}
                    onEditComplete={this.onEditComplete} /> }
          </TabPanel>
          <TabPanel value="2">
            {
              <QuaHenView
              service={this.nhanVienService}
              nhanVien={this.state.nhanVien}
              onEditComplete={this.onEditComplete} />
            }
          </TabPanel>
          <TabPanel value="3">
            {
              <NghiViecView
              service={this.nhanVienService}
              nhanVien={this.state.nhanVien}
              onEditComplete={this.onEditComplete} />
            }
          </TabPanel>
        </TabContext>
      </Stack>
    );
  }
}
export default DSHetPhepPage;
