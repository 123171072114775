import React, { Component } from 'react';
import Button from "@mui/material/Button";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Alert, Autocomplete, Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tab } from '@mui/material';
import { Stack } from '@mui/system';
import dayjs from 'dayjs';
import PhongBanService from '../../services/phongBanService';

import Validator from '../../utils/validate';
import rules from './NhanVienValidate';

const PAGE_SIZE = 10;

class SyncHRMPage extends Component {

    constructor(props) {
        super(props);
        this.service = props.service;
        this.phongBanService = new PhongBanService();
        this.rules = rules.filter(q => q.field != "dienThoai"
                                    &&  q.field != "ngayHetHan"
                                    &&  q.field != "ngayVaoLam"
                                    &&  q.field != "ngaySinh"
                                    &&  q.field != "visaLaoDong");
    }

    state = {
        nhanViens: [],
        errorList: [],
        items: [],
        pageCount: 0,
        page: 0,
        visible: false,
        alertMessage: null,
        focusAlert: null,
        phongbans: [],
        tabIndex: "1",
    }

    syncHRM = () => {
        this.setState({visible: false});
        const masks = document.getElementById('mask1');
        masks.style.visibility = "visible";
        this.service.syncHRM().then((nhanViens) => {
            nhanViens = nhanViens.sort(function(nv1, nv2){return nv1.maHRM.localeCompare(nv2.maHRM) || nv1.name.localeCompare(nv2.name)});
            let validList = [];
            let inValidList = [];
            for (let nv of nhanViens) {
                nv.checked = true;
                const validator = new Validator(this.rules);
                const errors = validator.validate(nv);
                if (validator.isValid) {
                    validList.push(nv);
                } else {
                    nv.errors = errors;
                    inValidList.push(nv);
                    this.setState({errorList: inValidList});
                }
            }
            this.changeList(validList, 0);
            this.setState({visible: true, alertMessage: null});
            masks.style.visibility = "hidden";

        }).catch((ex) => {
            masks.style.visibility = "hidden";
        });
    }

    syncHRMDB = () => {
        const nhanViens = this.state.nhanViens.filter(q => q.checked);
        const notPhongBans = nhanViens.filter(q => !q.PhongBan || !q.PhongBanId);
        if (notPhongBans && notPhongBans.length > 0) {
            this.setState({alertMessage: "Tồn tại nhân sự chưa nhập mã phòng ban"});
            const maNV = notPhongBans[0].maNV;
            this.setState({focusAlert: maNV});
            const findIndex = this.state.nhanViens.reduce((p, c, i, a) => 
                {
                    if (c.maNV === maNV) return i;
                    return p;
                }, 0);
            const page = Math.floor((findIndex + 1) / PAGE_SIZE) + 1;
            this.changePage(null, page);
            return;
        }
        this.service.syncData(nhanViens).then((syncResult) => {
            this.props.onSyncComplete();
        });
    }

    componentDidMount = () => {
        this.phongBanService.getDsPhongBan().then((rs) => {
            this.setState({phongbans: rs.items});
        });
    }

    changeList(nhanViens, page) {
        const pageCount = Math.ceil(nhanViens.length / PAGE_SIZE);
        const items = nhanViens.filter((v, i, a) => {return i >= (page * PAGE_SIZE) && i < ((page + 1) * PAGE_SIZE)});
        this.setState({nhanViens, pageCount, page: page + 1, items});
    }

    changePage = (ev, page) => {
        this.changeList(this.state.nhanViens, page - 1);
    }

    changeCheckBox = (ev) => {
        const nv = this.state.nhanViens.filter(q => q.maNV === ev.target.value)[0];
        nv.checked = ev.target.checked;
        this.changeList(this.state.nhanViens, this.state.page - 1);
    }

    changeAllCheckBox = (ev) => {
        let maNVs = this.state.items.map(q => q.maNV);
        for (let nv of this.state.nhanViens) {
            if (maNVs.includes(nv.maNV)) {
                nv.checked = ev.target.checked;
            }
        }
        this.changeList(this.state.nhanViens, this.state.page - 1);
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (nextProps.open !== this.props.open && nextProps.open) {
            this.changeList([], 0);
            this.syncHRM();
            return true;
        }
        return true;
    }

    changePhongBan = (name, pb) => {
        const nv = this.state.nhanViens.filter(q => q.maNV === name)[0];
        nv.PhongBan = pb;
        nv.PhongBanId = pb.id;
        this.changeList(this.state.nhanViens, this.state.page - 1);
    }

    renderError(item, key) {
        const isError = item.errors[key];
        let value = item[key];
        if (key.includes("ngay")) {
            value = dayjs(item[key]).format('DD/MM/YYYY');
        }
        if (key == "PhongBanId") {
            value = !item.PhongBan ? "" : item.PhongBan.name;
        }
        if (isError) {
            return (
                <>
                <div style={{ color: 'red' }}>
                    {value}
                </div>
                <div style={{ color: 'red' }}>
                    {item.errors[key]}
                </div>
                </>)
        } else {
            return value;
        }
    }

    render() { 
        return (
            <Dialog fullWidth={true} maxWidth="lg" open={this.props.open} onClose={this.props.onClose}>
            <DialogTitle>Đồng bộ nhân sự</DialogTitle>
                <DialogContent sx={{padding: "0px"}}>
                    <TabContext value={this.state.tabIndex}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={(ev, tabIndex) => {this.setState({tabIndex})}}>
                                <Tab label="Dữ liệu đúng" value="1" />
                                <Tab label="Dữ liệu sai" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <TableContainer sx={{height: "51vh"}}>
                                <Table size='small' aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                        <TableCell><Checkbox defaultChecked={true} onClick={this.changeAllCheckBox} /></TableCell>
                                        <TableCell>Mã nhân viên</TableCell>
                                        <TableCell>Tên nhân viên</TableCell>
                                        <TableCell>Ngày vào làm</TableCell>
                                        <TableCell>Chức vụ</TableCell>
                                        <TableCell>Phòng ban</TableCell>
                                        <TableCell>CMND/CCCD</TableCell>
                                        <TableCell>Hộ chiếu</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.items.map((item, i, a) => (
                                        <TableRow key={item.maNV}>
                                            <TableCell>
                                                <Checkbox
                                                    checked={item.checked}
                                                    value={item.maNV}
                                                    onChange={this.changeCheckBox} />
                                            </TableCell>
                                            <TableCell>{item.maNV}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{dayjs(item.ngayVaoLam).format('DD/MM/YYYY')}</TableCell>
                                            <TableCell>{item.chucVu}</TableCell>
                                            <TableCell>
                                                {!item.PhongBan ?
                                                <Autocomplete
                                                    title='Phòng ban'
                                                    onChange={(ev, pb) => this.changePhongBan(item.maNV, pb)}
                                                    options={this.state.phongbans}
                                                    groupBy={(option) => option.parent.name}
                                                    getOptionLabel={(option) => `${option.id} - ${option.name} - ${option.parent.name}`}
                                                    renderInput={(item) => (
                                                    <TextField
                                                        focused={this.state.focusAlert == item.maNV}
                                                        margin="dense"
                                                        {...item}
                                                        fullWidth
                                                        variant="standard"
                                                    />
                                                    )}
                                                />
                                                : item.PhongBan.name}
                                            </TableCell>
                                            <TableCell>{item.CMND}</TableCell>
                                            <TableCell>{item.hoChieu}</TableCell>
                                        </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Pagination sx={{display: "flex", justifyContent: "center"}} page={this.state.page} onChange={this.changePage} count={this.state.pageCount} variant="outlined" />
                        </TabPanel>
                        <TabPanel value="2">
                            <TableContainer sx={{height: "51vh"}}>
                                <Table size='small' aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                        <TableCell>Mã nhân viên</TableCell>
                                        <TableCell>Tên nhân viên</TableCell>
                                        <TableCell>Ngày vào làm</TableCell>
                                        <TableCell>Chức vụ</TableCell>
                                        <TableCell>CMND/CCCD</TableCell>
                                        <TableCell>Hộ chiếu</TableCell>
                                        <TableCell>Phòng ban</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.errorList.map((item, i, a) => (
                                        <TableRow key={item.maNV}>
                                            <TableCell>
                                                {this.renderError(item, "maNV")}
                                            </TableCell>
                                            <TableCell>{this.renderError(item, "name")}</TableCell>
                                            <TableCell>{this.renderError(item, "ngayVaoLam")}</TableCell>
                                            <TableCell>{this.renderError(item, "chucVu")}</TableCell>
                                            <TableCell>{this.renderError(item, "CMND")}</TableCell>
                                            <TableCell>{this.renderError(item, "hoChieu")}</TableCell>
                                            <TableCell>{this.renderError(item, "PhongBanId")}</TableCell>
                                        </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                    </TabContext>
            </DialogContent>
            <DialogActions sx={{width: "100%"}}>
                <Stack sx={{width: "100%"}}>
                    {
                        this.state.alertMessage ? 
                        <Alert variant="filled" severity="error">
                            ${this.state.alertMessage}
                        </Alert> :
                        ""
                    }
                    <Stack spacing={2} direction='row' justifyContent='end'>
                        <Button variant='contained' onClick={this.props.onClose}>Đóng</Button>
                        <Button variant='contained' onClick={this.syncHRMDB}>Đồng bộ</Button>
                    </Stack>
                </Stack>
            </DialogActions>
          </Dialog>
        );
    }
}
 
export default SyncHRMPage;