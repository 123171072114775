import { TreeView } from '@mui/lab';
import React, { Component } from 'react';
import PhongBanItem from './PhongBanItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

class PhongBanTree extends Component {
    constructor(props) {
        super(props);
        this.service = this.props.service;
        this.tree = this.props.tree;
    }
    state = {
        selectList: this.props.selectedPhongBans?[...this.props.selectedPhongBans]:[],
    }
    renderTreeView = (treeItem) => {
        let id = treeItem.id + "";
        return (
            <PhongBanItem
                checked={this.state.selectList.includes(treeItem.id)}
                onItemSelected={this.setChangeSelected}
                hasCheckBox={this.props.hasCheckBox}
                key={id}
                nodeId={id}
                label={treeItem.name}
                item={treeItem}
                onAddClick={this.props.onItemAddClick}
                onEditClick={this.props.onItemEditClick}
                onDeleteClick={this.props.onItemDeleteClick}>
                {treeItem.child ? treeItem.child.map((childT) => this.renderTreeView(childT)) : ""}
            </PhongBanItem>);
    }
    changeSelected = (item) => {
        let array = [];
        array.push(item.id);
        const childList = item.child ? item.child : [];
        for (const childItem of childList) {
            array = array.concat(this.changeSelected(childItem));
        }
        return array;
    }

    setChangeSelected = (item, selected) => {
        const selectedArray = this.changeSelected(item);
        let selectList = [...this.state.selectList];
        for (const i of selectedArray) {
            if(selected) {
                if (!selectList.includes(i)) {
                    selectList.push(i);
                }
            } else {
                const indexOf = selectList.indexOf(i);
                if (indexOf != -1) {
                    selectList.splice(indexOf, 1);
                }
            }
        }
        this.setState({selectList});
        if(this.props.onChangeSelected) this.props.onChangeSelected(selectList);
    }

    renderRoot = () => {
        this.items = {};
        return this.props.tree.map((item) => this.renderTreeView(item));
    }
    render() { 
        return (
        <>
            <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{ minHeight: this.props.height, flexGrow: 1, maxWidth: this.props.width, overflowY: "auto" }}>
                {this.props.tree.map((item) => this.renderTreeView(item))}
            </TreeView>
        </> );
    }
}
 
export default PhongBanTree;