import { Button, Grid, IconButton, Input, List, ListItem, ListItemButton, ListItemText, Pagination, Stack, Dialog, DialogActions, DialogTitle, DialogContent } from "@mui/material";
import React, { Component } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { Delete } from "@mui/icons-material";
import SyncHRMPage from "./SyncHRMPage";
import SyncDeepHRMPage from "./SyncDeepHRMPage";

class NhanVienList extends Component {
  constructor(props) {
    super(props);
    this.nhanvienService = props.service;
    this.syncPage = React.createRef();
  }
  state = {
    items: [],
    pageSize: 15,
    pageCount: 0,
    page: 1,
    selected: 1,
    keyword: "",
    openDelete: false,
    openSyncPage: false,
    openSyncDeepPage: false
  };
  componentDidMount = () => {
    this.findNhanVien();
  }
  changeSelected = (ev, index, item) => {
    this.setState({selected : index});
    this.props.onSelectedNV(item);
  }
  findNhanVien = (keyword = this.state.keyword, page = this.state.page) => {
    this.nhanvienService.findByKeyword(keyword, page).then((rs) => {
      let pageCount = parseInt(rs.count / this.state.pageSize) + (rs.count % this.state.pageSize === 0 ? 0 : 1);
      this.setState({items: rs.items, keyword, page, pageCount});
      if (rs.count > 0) {
        this.changeSelected(null, 0, rs.items[0]);
      }
    });
  }
  onClose = (ev) => {
    this.setState({openDelete: false})
  }
  delete = (ev) => {
    this.nhanvienService.delete(this.state.items[this.state.selected].id).then((rs) => {
      this.findNhanVien();
      this.setState({openDelete: false});
    });
  }
  getSync = (ev) => {
    this.setState({openSyncPage: true});
  }
  onCloseSync = (ev, reason) => {
    if (reason !== "backdropClick") {
      this.setState({openSyncPage: false});
    }
  }
  onSyncComplete = () =>  {
    this.findNhanVien("", 1);
    this.setState({openSyncPage: false, openSyncDeepPage: false})
  }
  
  changePage = (ev, page) => {
    this.findNhanVien(this.state.keyword, page);
  }

  onEnter = (ev) => {
    if (ev.key === 'Enter') {
      this.findNhanVien(this.state.keyword, 1);
    }
  }

  render() {
    return (
      <>
      <SyncDeepHRMPage
        open={this.state.openSyncDeepPage}
        onClose={() => this.setState({openSyncDeepPage: false})}
        service={this.nhanvienService}
        onInsertCompleted={this.onSyncComplete}
        />
      <SyncHRMPage
        open={this.state.openSyncPage}
        onClose={() => this.setState({openSyncPage: false})}
        service={this.nhanvienService}
        ref={this.syncPage}
        onSyncComplete={this.onSyncComplete}></SyncHRMPage>
      <Dialog fullWidth={true} open={this.state.openDelete} onClose={this.onClose}>
          <DialogTitle>Thông báo</DialogTitle>
          <DialogContent>
              Bạn có chắc chắn muốn xoá
          </DialogContent>
          <DialogActions>
              <Button onClick={this.onClose}>Đóng</Button>
              <Button onClick={this.delete}>Xoá</Button>
          </DialogActions>
      </Dialog>
      <Stack sx={{padding:"0.3rem", width: "23%", position: "fixed"}} justifyContent="space-between">
        <Stack direction="row">
            <Input
              onKeyUp={(ev) => {this.onEnter(ev)}}
              onChange={(ev) => {this.setState({keyword : ev.target.value})}}
              sx={{width:"100%"}}
              placeholder="Tìm theo tên hay mã" />
            <IconButton
              onClick={() => this.findNhanVien(this.state.keyword, 1)}>
                <SearchIcon />
            </IconButton>
        </Stack>
        <List sx={{height: '70vh', overflowY: 'scroll'}}>
          {this.state.items.map((item, index, array) => {
            return(
              <ListItem key={item.id} disablePadding>
                <ListItemButton
                    selected={this.state.selected === index}
                    onClick={(ev) => this.changeSelected(ev, index, item)}>
                  <ListItemText primary={`${item.maNV} - ${item.name}`} />
                  <IconButton onClick={(ev) => this.setState({openDelete: true})}>
                    <Delete></Delete>
                  </IconButton>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <Pagination value={this.state.page} sx={{display: "flex", justifyContent: "center", margin: "0.2rem 0px"}} onChange={this.changePage} count={this.state.pageCount} variant="outlined" siblingCount={0} boundaryCount={1} />
        <Grid direction="row" spacing={2} justifyContent="center">
            <Button size="medium" variant="contained" sx={{width:"48%", margin: "0.1rem"}} onClick={this.getSync} >Đồng bộ</Button>
            <Button size="medium" variant="contained" sx={{width:"48%", margin: "0.1rem"}} onClick={() => {this.setState({openSyncDeepPage: true})}}>Thêm mới</Button>
        </Grid>
      </Stack>
      </>
    );
  }
}

export default NhanVienList;
