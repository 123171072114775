import React, { Component } from "react";
import NhanVienView from "./NhanVienView";
import NhanVienList from "./NhanVienList";
import NhanVienService from "../../services/nhanvienService";
import { Grid, Tab } from "@mui/material";
import VePhepPage from "../vephep/VePhepPage";
import { Box } from "@mui/system";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const drawerWidth = 240;
class NhanVienPage extends Component {
  constructor(props) {
    super(props);
    this.nhanVienService = new NhanVienService();
    this.list = React.createRef();
  }
  state = {
    nhanVien: {},
    tabIndex: "1"
  };
  changeNhanVien = (nhanVien) => {
    this.setState({ nhanVien });
  };
  onEditComplete = (nv) => {
    this.list.current.onSyncComplete();
  }
  render() {
    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            >
            <Grid item xs={3.5}>
                <NhanVienList
                    ref={this.list}
                    service={this.nhanVienService}
                    onSelectedNV={this.changeNhanVien} />
            </Grid>
            <Grid item xs={8.5}>
              <TabContext value={this.state.tabIndex}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={(ev, tabIndex) => {this.setState({tabIndex})}} aria-label="Thông tin nhân viên">
                    <Tab label="Thông tin nhân viên" value="1" />
                    <Tab label="Lịch sử về phép" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <NhanVienView
                    service={this.nhanVienService}
                    nhanVien={this.state.nhanVien}
                    onEditComplete={this.onEditComplete} />
                </TabPanel>
                <TabPanel value="2">
                  {
                    !this.state.nhanVien?
                    "Không có dữ liệu nhân viên"
                    :
                    <VePhepPage NhanVienId={this.state.nhanVien.id}/>
                  }
                </TabPanel>
              </TabContext>
            </Grid>
        </Grid>
    );
  }
}

export default NhanVienPage;
