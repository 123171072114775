import requestHelper from "../utils/requestHelper";

class ThongKeService {
    getThongKe = async(query) => {
        const rs = await requestHelper.post('/api/thongke/bdns', query);
        return rs;
    }

    getExcelThongKe = async(query) => {
        await requestHelper.download('/api/excel/bdns', query, "BaoCaoBDNS");
    }

    getThongKeLyDo = async(query) => {
        const rs = await requestHelper.post('/api/thongke/ldkql', query);
        return rs;
    }

    getExcelThongKeLyDo = async(query) => {
        await requestHelper.download('/api/excel/ldkql', query, "ThongKeTheoLyDo");
    }

    getThongKeDKQL = async(query) => {
        const rs = await requestHelper.post('/api/thongke/dkql', query);
        return rs;
    }

    getExcelThongKeDKQL= async(query) => {
        await requestHelper.download('/api/excel/dkql', query, "KeHoachNSQuayLai");
    }
    // getThongKeNSVP = async(query) => {
    //     const rs = await requestHelper.post('/api/thongke/nsvp', query);
    //     return rs;
    // }

    getExcelThongKeNSVP= async(query) => {
        await requestHelper.download('/api/excel/nsvp', query,"NhatKyVePhep");
    }
    getExcelThongKeTBNSVP= async(query) => {
        await requestHelper.download('/api/excel/tbns', query,"NhatKyToanBoNS");
    }

    getDanhSachQuayLai= async(query) => {
        return await requestHelper.post('/api/nhanvien/dsQuayLaiTheoNgay', query);
    }
}

export default ThongKeService;