import React, { Component } from "react";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { Box } from '@mui/system';
import TKLdkqlPage from './TKLdkqlPage';
import TKBDNSPage from './TKBDNSPage';
import TKDKQLPage from "./TKDKQLPage";
import TKNSVPPage from "./TKNSVPPage";

class ThongKePage extends Component {
    state = { 
        tabIndex: "1"
     }
    render() { 
        return (
            <TabContext value={this.state.tabIndex}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(ev, tabIndex) => {this.setState({tabIndex})}}>
                        <Tab label="Thống kê biến động nhân sự" value="1" />
                        <Tab label="Thống kê biến động nhân sự theo lý do" value="2" />
                        <Tab label="Kế hoạch nhân sự quay lại làm việc" value="3" />
                        <Tab label="Nhật ký nhân sự về phép" value="4" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <TKBDNSPage></TKBDNSPage>
                </TabPanel>
                <TabPanel value="2">
                    <TKLdkqlPage></TKLdkqlPage>
                </TabPanel>
                <TabPanel value="3">
                    <TKDKQLPage></TKDKQLPage>
                </TabPanel>
                <TabPanel value="4">
                    <TKNSVPPage></TKNSVPPage>
                </TabPanel>
            </TabContext>
        );
    }
}
 
export default ThongKePage;