import './thongke.css'

import { Button, FormControl, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,Dialog, DialogActions, DialogContent } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { Component } from "react";
import ThongkeService from '../../services/thongkeService';
import NhanVienService from '../../services/nhanvienService';
const thongKeService = new ThongkeService();
const nhanVienService = new NhanVienService();

class TKLdkqlPage extends Component {
  state = {
    ngayThongKe: (new Date()).setDate(new Date().getDate() - new Date().getDate() + 1),
    ngayThongKes: (new Date()).setDate(new Date().getDate() - new Date().getDate() + 1),
    items: [],
    dsnv: [],
    errors: {},
    openDialog: false,
    hienQuayLai: false,
    count: 0,
  };

  getThongKe = (ev) => {
    if (!this.validate()) {
      return;
    }
    thongKeService.getThongKeLyDo({ ngayThongKe: dayjs(this.state.ngayThongKe).toDate() }).then(items => {
      this.setState({ items })
    });
  }

  getExcelThongKe = (ev) => {
    if (!this.validate()) {
      return;
    }
    thongKeService.getExcelThongKeLyDo({ ngayThongKe: dayjs(this.state.ngayThongKe).format("YYYY-MM-DD")}).then(items => {
    });
  }
  componentDidMount() {
    this.getCountDsHetPhep();
  }
  getCountDsHetPhep = () => {
    nhanVienService.countDStTheoDoiNghiPhep().then((rs) =>{
      this.setState({count: rs.count});
    });
  }

  validate = () => {
    if (!dayjs(this.state.ngayThongKe).toDate()) {
      this.setState({ errors: { ngayThongKe: "Ngày bắt đầu không hợp lệ" } });
      return false;
    }

    if (dayjs(this.state.ngayThongKe).toDate() >= dayjs(this.state.toDate).toDate()) {
      this.setState({ errors: { ngayThongKe: "Ngày bắt đầu phải nhỏ hơn ngày kết thúc" } });
      return false;
    }
    return true;
  }
  layNsHpCQL(congTyId, hienQuayLai) {
    let query = {
      ngayThongKe: dayjs(this.state.ngayThongKe).format('YYYY-MM-DD'),
      ngayThongKes: dayjs(this.state.ngayThongKe).format('YYYY-MM-DD'),
      congTyId,
    };
    nhanVienService.getNsHetPhepCQL(query).then((dsnv) =>{
      this.setState({dsnv, openDialog:true, hienQuayLai});
    });
  }
  layldVePhepKQ(congTyId, hienQuayLai) {
    let query = {
      ngayThongKe: dayjs(this.state.ngayThongKe).format('YYYY-MM-DD'),
      congTyId,
    };
    nhanVienService.getLyDoVePhepKQ(query).then((dsnv) =>{
      this.setState({dsnv, openDialog:true, hienQuayLai});
    });
  }
  layldVePhepCN(congTyId, hienQuayLai) {
    let query = {
      ngayThongKe: dayjs(this.state.ngayThongKe).format('YYYY-MM-DD'),
      congTyId,
    };
    nhanVienService.getLyDoVePhepCN(query).then((dsnv) =>{
      this.setState({dsnv, openDialog:true, hienQuayLai});
    });
  }
  laydsChamDutDHLD(congTyId, hienQuayLai) {
    let query = {
      ngayThongKe: dayjs(this.state.ngayThongKe).format('YYYY-MM-DD'),
      congTyId,
    };
    nhanVienService.getChamDutHDLD(query).then((dsnv) =>{
      this.setState({dsnv, openDialog:true, hienQuayLai});
    });
  }
  
  renderRow = (klh) => {
    const congTys = klh.congTys.map(congTy => this.rederDonvi(congTy))
    return (
      <>
        {this.rederDonvi(klh, true)}
        {congTys}
      </>
    )
  }

  rederDonvi(donvi, primary = false) {
    return (
      <TableRow className={primary ? 'klh' : ''}>
        <TableCell>{donvi.stt}</TableCell>
        <TableCell className='left'>{donvi.item.name}</TableCell>
        <TableCell>
        {
          donvi.SLVePhep <= 0 || primary ? 
          donvi.SLVePhep
          : <Button onClick={() => this.layNsHpCQL(donvi.item.id,true)}>{donvi.SLVePhep}</Button>
        }
      </TableCell>
        <TableCell>{this.showPercent(donvi.ptVePhep)}</TableCell>
        <TableCell>
        {
          donvi.SLLydo1 <= 0 || primary ? 
          donvi.SLLydo1
          : <Button onClick={() => this.layldVePhepKQ(donvi.item.id,true)}>{donvi.SLLydo1}</Button>
        }
        </TableCell>
        <TableCell>{this.showPercent(donvi.ptLydo1)}</TableCell>
        
        <TableCell>
        {
          donvi.SLLydo2 <= 0 || primary ? 
          donvi.SLLydo2
          : <Button onClick={() => this.layldVePhepCN(donvi.item.id,true)}>{donvi.SLLydo2}</Button>
        }
        </TableCell>
        <TableCell>{this.showPercent(donvi.ptLydo2)}</TableCell>
        
        <TableCell>
        {
          donvi.SLNghiViec <= 0 || primary ? 
          donvi.SLNghiViec
          : <Button onClick={() => this.laydsChamDutDHLD(donvi.item.id,true)}>{donvi.SLNghiViec}</Button>
        }
        </TableCell>
        <TableCell>{this.showPercent(donvi.ptNghiViec)}</TableCell>
      </TableRow>
    )
  }

  showPercent(percent) {
    if (!percent && percent !== 0) return '-';
    let str = percent * 100;
    str = str.toFixed(2) + '%';
    return str;
  }

  render() {
    return (
      <>
      <Dialog maxWidth="lg" open={this.state.openDialog}>
          <DialogContent>
          <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
              <TableCell>STT</TableCell>
              <TableCell>Tên</TableCell>
                <TableCell>Phòng ban</TableCell>
                <TableCell>Ngày nghỉ phép</TableCell>
                <TableCell>Ngày hết phép</TableCell>
                {
                  this.state.hienQuayLai?<TableCell>Ngày hẹn lại</TableCell>:" "
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.dsnv.map((item,index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.TenPhongBan}</TableCell>
                <TableCell>{dayjs(item.ngayDi).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{dayjs(item.ngayHetPhep).format('DD/MM/YYYY')}</TableCell>
                {
                  this.state.hienQuayLai?<TableCell>{dayjs(item.ngayHenLai).isValid() ?dayjs(item.ngayHenLai).format('DD/MM/YYYY'):" "}</TableCell>:" "
                }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
          </DialogContent>
          <DialogActions>
              <Button onClick={(ev) => {this.setState({openDialog: false})}}>Đóng</Button>
          </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        <Grid
          item
          xs={4}
          className={!this.state.errors.ngayThongKe ? "" : "custom-error-message"}
        >
          <FormControl fullWidth>
            <LocalizationProvider
              sx={{ innerWidth: "100%" }}
              dateAdapter={AdapterDayjs}
            >
              <DatePicker
                label="Ngày thống kê"
                inputFormat="DD/MM/YYYY"
                value={dayjs(this.state.ngayThongKe)}
                onChange={(ngayThongKe) => { this.setState({ ngayThongKe: dayjs(ngayThongKe).toDate() }) }}
                renderInput={(params) => <TextField {...params} />}
              />
              <div className="error-message">{this.state.errors.ngayThongKe}</div>
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={4}
        >
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" onClick={this.getThongKe}>Tìm kiếm</Button>
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" onClick={this.getExcelThongKe}>Tải báo cáo</Button>
        </Grid>
        <Grid item xs={12}>
          <h4 className='warning'>
          {this.state.count > 0 ?"Yêu cầu nhập đầy đủ thông theo dõi nghỉ phép trước khi tải báo cáo": " "}
          </h4>
          {
            this.state.items.length === 0 ?
              <h4> Không có dữ liệu </h4>
              :
              <TableContainer component={Paper}>
                <Table
                  className='thongke'
                  sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell rowSpan={3}>STT</TableCell>
                      <TableCell rowSpan={3}>Đơn vị</TableCell>
                      <TableCell colSpan={2} rowSpan={2}>SL Nhân sự hết phép & chưa quay lại làm việc sau ngày {dayjs(this.state.ngayThongKe).format('DD/MM/YYYY')}</TableCell>
                      <TableCell colSpan={6}>Trong đó:</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>Chờ hoàn tất thủ tục hoặc lý do khách quan</TableCell>
                      <TableCell colSpan={2}>Lý do cá nhân từ người lao động</TableCell>
                      <TableCell colSpan={2}>Nhân sự chấm dứt HĐLĐ</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Số lượng</TableCell>
                      <TableCell>Tỷ lệ</TableCell>

                      <TableCell>Số lượng</TableCell>
                      <TableCell>Tỷ lệ</TableCell>

                      <TableCell>Số lượng</TableCell>
                      <TableCell>Tỷ lệ</TableCell>

                      <TableCell>Số lượng</TableCell>
                      <TableCell>Tỷ lệ</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.items.map((item) => (
                      this.renderRow(item)
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
          }
        </Grid>
      </Grid>
      </>
    );
  }
}

export default TKLdkqlPage; 