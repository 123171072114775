import React, { Component } from 'react';

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import {Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, Select} from "@mui/material"

import Validator from "../../utils/validate";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MenuItem } from "@mui/material";
import dayjs from "dayjs";
import {rules, isEditTrangThai} from './VePhepValidate';

import {LoaiVePheps, TrangThais, DeXuatHenQuayLai} from "../../utils/cons";
import HenQuayLaiPage from '../henquaylai/HenQuayLaiPage';
import HenQuayLaiEditView from '../henquaylai/HenQuayLaiEditView';
import HenQuayLaiService from '../../services/henQuayLaiService';
import LyDoService from '../../services/lyDoService';

class VePhepDialog extends Component {
  constructor(props) {
    super(props);
    this.rules = [...rules];
    this.henLaiService = new HenQuayLaiService();
    this.lyDoService = new LyDoService();
    this.henLaiError = null;
    this.isEditNgay = false;
  }

  state = { 
    model: {},
    errors: {},
    displayTrangThai: "none",
    henLaiErrors: {},
    henLais: [],
    lyDos: [],
    maxHenLai: new Date(),
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (prevProps.open === false && this.props.open === true) {
      let displayTrangThai = isEditTrangThai(this.props.model) ? "block" : "none";
      let model = Object.assign({}, this.props.model);
      model.ngayHenLai = this.state.henLais.length > 0 ? this.state.henLais[0].ngayHenLai : this.state.model.ngayHetPhep;
      model.ngayHenLai = prevProps.model.ngayHenLai < new Date() ? new Date() : prevProps.model.ngayHenLai;
      model.henQuayLai = this.newHenVePhepModel();
      this.isEditNgay = false;

      this.setState({model, errors: {}, displayTrangThai});
      this.loadHenLai(model.id);
    }
  }

  loadHenLai = (henLaiId = null) => {
    let id = henLaiId;
    if (!henLaiId) {
      id = this.props.model.id;
    }
    this.henLaiService.getList({VePhepId: id, order: "D_ngayHenLai"}).then((rs) => {
      this.setState({henLais: rs.items});
      if(rs.items[0].deXuat === DeXuatHenQuayLai[1]) {
        this.props.service.getById(id).then((model) => {
          model.CuaKhau = this.props.cuaKhaus.filter(q => q.id == model.CuaKhauId);
          model.CuaKhau = model.CuaKhau[0];
          this.setState({model})
        })
      }
    });
    this.lyDoService.getList({}).then((rs) => {
      this.setState({lyDos: rs.items});
    });
    if(this.props.loadHenLai) this.props.loadHenLai();
  }

  handleChange = (value, name, forModel = null) => {
    if (!forModel) {
      const model = Object.assign({}, this.state.model);
      model[name] = value;
      if (name === "ngayDi" && dayjs(model.ngayDi).toDate() != dayjs(this.state.model.ngayDi).toDate()) {
        this.isEditNgay = true;
      }
      if (name === "ngayHetPhep" && dayjs(model.ngayHetPhep).toDate() != dayjs(this.state.model.ngayHetPhep).toDate()) {
        this.isEditNgay = true;
      }
      if (!model.id && name === "ngayHetPhep") {
        model.ngayQuayLai = model.ngayHetPhep;
      }
      if ((name + "Id") in model) {
        model[name + "Id"] = model[name].id;
      }
      let ngayNghiViec = model.ngayNghiViec;
      if (!ngayNghiViec) {
        ngayNghiViec = model.ngayHetPhep;
        if (this.state.henLais && this.state.henLais.length > 0) {
          ngayNghiViec = this.state.henLais[0].ngayHenLai;
        }
      }
      if (name === "trangThai") {
        switch(TrangThais.indexOf(model.trangThai)) {
          case 0:
            model.ngayQuayLai = model.ngayHetPhep;
            break;
          case 1:
          case 2:
            model.ngayNghiViec = ngayNghiViec;
            break;
          case 3:
            this.newHenVePhepModel();
            model.ngayHenLai = this.state.henLais.length > 0 ? this.state.henLais[0].ngayHenLai : model.ngayHetPhep;
            model.ngayHenLai = model.ngayHenLai < new Date() ? new Date() : model.ngayHenLai;
            break;
          default:
            break;
        }
      }
      if (name = "loaiVePhep" && value === LoaiVePheps[1]) {
        model.VePhepId = null;
        model.VePhep = null;
      }
      this.setState({model});
    } else {
      let model = Object.assign({}, this.state[forModel]);
      model[name] = value;
      this.setState({forModel: model});
    }
  }

  save = () => {
    this.validator = new Validator(this.rules);
    this.state.model.isEditNgay = this.isEditNgay;
    const errors = this.validator.validate(this.state.model);
    this.setState({errors});
    this.validator.isValid = this.validator.isValid && !this.henLaiError;
    if (!this.validator.isValid) {
        return;
    }
    if (!this.state.model.id) {
      if (!errors['ngayDi']) {
        errors['ngayDi'] = this.customValidate(this.state.model.ngayDi);
        if (errors['ngayDi']) {
          this.setState({errors});
          return;
        }
      }
      this.props.service.create(this.state.model).then(() => {
        this.props.onChange();
      })
    } else {
      this.props.service.update(this.state.model).then(() => {
        this.props.onChange(this.state.model.trangThai === TrangThais[3]);
        this.loadHenLai();
      })
    }
  }

  newHenVePhepModel() {
    let ngayHenLai = this.state.henLais.length > 0 ? this.state.henLais[0].ngayHenLai : this.state.model.ngayHetPhep;
    ngayHenLai = dayjs(ngayHenLai).toDate();
    ngayHenLai.setDate(ngayHenLai.getDate() + 1);
    this.maxHenLai = ngayHenLai;
    const henVePhep = {
      ngayHenLai,
      kichHoat: 1,
      LyDoId : 1,
      deXuat: DeXuatHenQuayLai[0],
    }
    return henVePhep;
  }

  customValidate = (ngayDi) => {
    ngayDi = dayjs(ngayDi).toDate();
    let vePhepFilter = this.props.items.filter(q => dayjs(q.ngayDi).toDate() <= ngayDi && dayjs(q.ngayQuayLai).toDate() >= ngayDi);
    if (vePhepFilter.length > 0) {
      return "Ngày đi đã vi phạm với ngày trong quá khứ";
    }
    return null;
  }

  render() { 
    return (
      <Dialog maxWidth={true} open={this.props.open} onClose={this.props.onClose}>
      <DialogTitle>
        {!this.state.model.id ? "Thêm mới " : "Chỉnh sửa "}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{marginTop: "0.8rem"}}>
            <InputLabel id="loai_ve_phep">Loại về phép</InputLabel>
            <Select
              labelId="loai_ve_phep"
              id="loai_ve_phep_select"
              value={this.state.model.loai}
              label="Loại về phép"
              onChange={(ev) => this.handleChange(ev.target.value, 'loai')}>
                {this.props.loaiVePheps.map((loai, i, a) => (
                  <MenuItem key={i} value={loai}>{loai}</MenuItem>
                ))}
            </Select>
        </FormControl>
        <Grid container sx={{marginTop: "0.2rem"}} spacing={1}>
          <Grid item xs={6} className={!this.state.errors.ngayDi ? "" : "custom-error-message"}>
            <FormControl fullWidth>
              <LocalizationProvider
                sx={{innerWidth: "100%"}} dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Ngày đi"
                  inputFormat="DD/MM/YYYY"
                  name="ngayDi"
                  value={dayjs(this.state.model.ngayDi)}
                  onChange={(ev) => this.handleChange(ev, "ngayDi")}
                  renderInput={(params) => <TextField label="Ngày đi" {...params} />}
                />
                <div className='error-message'>{this.state.errors.ngayDi}</div>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={6} className={!this.state.errors.ngayHetPhep ? "" : "custom-error-message"}>
            <FormControl fullWidth>
              <LocalizationProvider
                sx={{innerWidth: "100%"}} dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Ngày hết phép"
                  inputFormat="DD/MM/YYYY"
                  name="ngayHetPhep"
                  value={dayjs(this.state.model.ngayHetPhep)}
                  onChange={(ev) => this.handleChange(ev, "ngayHetPhep")}
                  renderInput={(params) => <TextField {...params} />}
                />
                <div className='error-message'>{this.state.errors.ngayHetPhep}</div>
                </LocalizationProvider>
              </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <FormControlLabel control={<Checkbox checked={this.state.model.xepXeDi} onChange={(ev) => this.handleChange(ev.target.checked, "xepXeDi")} />} label="Sắp xếp xe đi" />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <FormControlLabel control={<Checkbox checked={this.state.model.xepXeVe} onChange={(ev) => this.handleChange(ev.target.checked, "xepXeVe")} />} label="Sắp xếp xe về" />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
                <InputLabel id="cua_khau_label">Cửa khẩu</InputLabel>
                <Select
                  labelId="cua_khau_label"
                  id="cua_khau_select"
                  value={this.state.model.CuaKhau}
                  label="Cửa khẩu"
                  onChange={(ev) => this.handleChange(ev.target.value, "CuaKhau")}>
                    {this.props.cuaKhaus.map((cuaKhau) => (
                      <MenuItem key={cuaKhau.id} value={cuaKhau}>{cuaKhau.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
          </Grid>
          <Grid item xs="12">
            <TextField
              autoFocus
              margin="dense"
              id="lyDo"
              name='lyDo'
              label="Lý do"
              type="text"
              fullWidth
              variant="standard"
              value={this.state.model.lyDo}
              onChange={(ev) => this.handleChange(ev.target.value, ev.target.name)}
              error={this.state.errors.lyDo}
              helperText={this.state.errors.lyDo}
            />
          </Grid>
        </Grid>
        <Grid
          sx={{display: this.state.displayTrangThai, marginTop: "0.5rem"}}
          container id="edit_grid" spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
                <InputLabel id="trang_thai_label">Trạng thái</InputLabel>
                <Select
                  error={this.state.errors.trangThai}
                  helperText={this.state.errors.trangThai}
                  value={this.state.model.trangThai}
                  labelId="trang_thai_label"
                  id="trang_thai_select"
                  name='trangThai'
                  label="Trạng thái"
                  onChange={(ev) => this.handleChange(ev.target.value, "trangThai")}>
                    {TrangThais.map((tt, i) => (
                      <MenuItem key={`${tt}_${i}`} value={tt}>{tt}</MenuItem>
                    ))}
                </Select>
                <FormHelperText style={{color: 'red'}}>
                  {this.state.errors.trangThai}
                </FormHelperText>
            </FormControl>
          </Grid>
          {/* Hiện khi chọn đã về */}
          <Grid
            sx={{display: this.state.model.trangThai === TrangThais[0] ? "block" : "none"}}
            item xs={12} className={!this.state.errors.ngayQuayLai ? "" : "custom-error-message"}>
            <FormControl fullWidth>
              <LocalizationProvider
                sx={{innerWidth: "100%"}} dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Ngày quay lại làm việc thực tế"
                  inputFormat="DD/MM/YYYY"
                  name="ngayQuayLai"
                  value={dayjs(this.state.model.ngayQuayLai)}
                  onChange={(ev) => this.handleChange(ev, "ngayQuayLai")}
                  renderInput={(params) => <TextField label="Ngày quay lại thực tế" {...params} />}
                />
                <div className='error-message'>{this.state.errors.ngayQuayLai}</div>
                </LocalizationProvider>
              </FormControl>
          </Grid>
          {/* Hiện khi chọn nghỉ việc - dự kiến nghỉ việc */}
          <Grid
            sx={{display: this.state.model.trangThai === TrangThais[1] || this.state.model.trangThai === TrangThais[2] ? "block" : "none"}}
            item xs={12} className={!this.state.errors.ngayNghiViec ? "" : "custom-error-message"}>
            <FormControl fullWidth>
              <LocalizationProvider
                sx={{innerWidth: "100%"}} dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={this.state.model.trangThai === TrangThais[1] ? "Ngày nghỉ dự kiến" : "Ngày nghỉ việc"}
                  inputFormat="DD/MM/YYYY"
                  name="ngayNghiViec"
                  value={dayjs(this.state.model.ngayNghiViec)}
                  onChange={(ev) => this.handleChange(ev, "ngayNghiViec")}
                  renderInput={(params) => <TextField label={this.state.model.trangThai === TrangThais[1] ? "Ngày nghỉ dự kiến" : "Ngày nghỉ việc"} {...params} />}
                />
                <div className='error-message'>{this.state.errors.ngayNghiViec}</div>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          {/* Hiện khi chọn hẹn lại */}
          {
            (this.state.model.trangThai === TrangThais[3] && this.state.henLais.length === 0) ?
            <HenQuayLaiEditView
              lyDos={this.state.lyDos}
              model={this.state.model.henQuayLai}
              maxHenLai={this.maxHenLai}
              handleChange={(henQuayLai, error) => {
                this.henLaiError = error;
                this.handleChange(henQuayLai, "henQuayLai")
              }} />
            :
            ""
          }
          {/* Hiện danh sách hẹn lại */}
          {
            this.state.henLais.length > 0 ? 
          <Grid item xs={12}>
            <h4>Danh sách hẹn lại</h4>
                <HenQuayLaiPage
                  lyDos={this.state.lyDos}
                  items={this.state.henLais}
                  service={this.henLaiService}
                  vePhepId={this.state.model.id}
                  vePhep={this.state.model}
                  vePheps={this.props.items}
                  reload={this.loadHenLai} />
              </Grid>
            : ""
          }
          
          </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.props.onClose} variant="contained">Đóng</Button>
        <Button onClick={this.save} variant="contained">
          {!this.state.model.id ? "Thêm mới " : "Lưu"}
        </Button>
      </DialogActions>
    </Dialog>
    );
  }
}
 
export default VePhepDialog;
