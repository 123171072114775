import React, { Component } from "react";
import Service from "../../services/nhanvienService";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, Pagination, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { Edit } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import VePhepDialog from "../vephep/VePhepDialog";
import CuaKhauService from "../../services/cuaKhauService";
import VePhepService from "../../services/vePhepService";

import { LoaiVePheps, TrangThais } from "../../utils/cons";
import LyDoService from "../../services/lyDoService";
import dayjs from "dayjs";
const service = new Service();
const MAX_PAGE_SIZE = 10;

class HetPhepView extends Component {
  constructor(props) {
    super(props);
    this.vePhepService = new VePhepService();
    this.cuaKhauService = new CuaKhauService();
    this.lyDoService = new LyDoService();
    this.firstTime = true;
  }

  state = {
    items: [],
    keyword: "",
    pageCount: 0,
    offset: 1,

    item: {},
    open: false,
    loaiVePheps: [],
    minDate: new Date(),
    cuaKhaus: [],
    lyDoHenLais: []
  };

  onClose = () => {
    this.setState({open: false});
  }
  
  componentDidMount = () => {
    this.search();
    this.cuaKhauService.getList({}).then((rs) => {
      this.setState({cuaKhaus: rs.items});
    });
    this.lyDoService.getList().then((rs) => {
      this.setState({lyDoHenLais: rs.items});
    });
  };

  search = () => {
    this.loadList(1);
  }

  loadList(page = 1) {
    service.searchHetPhep(this.state.keyword, page, MAX_PAGE_SIZE).then((rs) => {
      let pageCount = parseInt(rs.count/ MAX_PAGE_SIZE) + (rs.count % MAX_PAGE_SIZE === 0 ? 0 : 1);
      this.setState({items: rs.items, pageCount})
    });
    this.setState({ openDialog: false, offset: page });
  }

  // on click edit item
  openEditDialog = (ev, item) => {
    this.vePhepService.getById(item.VePhepId).then((vePhep) => {
      vePhep.CuaKhau = this.state.cuaKhaus.filter(q => q.id === vePhep.CuaKhauId);
      vePhep.CuaKhau = vePhep.CuaKhau.length === 0 ? null : vePhep.CuaKhau[0];
      
      let open = ev !== null;
      this.setState({item: vePhep, open});
    })
  };

  ongChangePageCount = (ev, page) => {
    this.loadList(page);
  }

  render() {
    return (
        <Stack spacing={2}>
          <Grid margin={1} container spacing={1}>
            <Grid item xs={9}>
              <TextField id="outlined-basic"
                fullWidth
                onChange={(ev) => {this.setState({keyword: ev.target.value})}}
                size="small" variant="outlined" sx={{float: "left"}} />
            </Grid>
            <Grid item xs={2.5}>
              <Button fullWidth variant="contained" onClick={this.search} sx={{float: "right"}}>Tìm kiếm</Button>
            </Grid>
          </Grid>

          <VePhepDialog
            items={this.state.items}
            service={this.vePhepService}
            onChange={() => {this.search(); this.onClose()}}
            loadHenLai={() => {this.search()}}
            open={this.state.open}
            onClose={() => this.setState({open: false})}
            model={this.state.item}
            minDate={this.state.minDate}
            loaiVePheps={LoaiVePheps}
            cuaKhaus={this.state.cuaKhaus} />
        
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>STT</TableCell>
                  <TableCell>Mã nhân viên</TableCell>
                  <TableCell>Tên</TableCell>
                  <TableCell>Ngày vào làm</TableCell>
                  <TableCell>Phòng ban</TableCell>
                  <TableCell>Khu liên hợp</TableCell>
                  <TableCell>Cửa Khẩu</TableCell>
                  <TableCell>Số điện thoại</TableCell>
                  <TableCell>Ngày nghỉ phép</TableCell>
                  <TableCell>Ngày hết phép</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.items.map((item,index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.maNV}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{dayjs(item.ngayVaoLam).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{item.TenPhongBan}</TableCell>
                    <TableCell>{item.KhuLienHop}</TableCell>
                    <TableCell>{item.CuaKhau}</TableCell>
                    <TableCell>{item.dienThoai}</TableCell>
                    <TableCell>{dayjs(item.ngayDi).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{dayjs(item.ngayHetPhep).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>
                    <IconButton onClick={(ev) => this.openEditDialog(ev, item)}>
                      <Edit />
                    </IconButton>
                  </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination onChange={this.ongChangePageCount} sx={{display: "flex", justifyContent: "center"}} count={this.state.pageCount} variant="outlined" />
        </Stack>
    );
  }
}

export default HetPhepView;
