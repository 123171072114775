import './thongke.css'

import { Button, Dialog, DialogActions, DialogContent, FormControl, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { Component } from "react";
import ThongkeService from '../../services/thongkeService';
import NhanVienService from '../../services/nhanvienService';
const nhanVienService = new NhanVienService();
const thongKeService = new ThongkeService();

class TKDKQLPage extends Component {
  state = {
    fromDate: (new Date()).setDate(new Date().getDate() - new Date().getDate()),
    toDate: new Date(),
    items: [],
    errors: {},

    openDialog: false,
    dsnv: [],
    count: 0
  };

  getThongKe = (ev) => {
    if (!this.validate()) {
      return;
    }
    thongKeService.getThongKeDKQL({fromDate: dayjs(this.state.fromDate).toDate(), toDate: dayjs(this.state.toDate).toDate()}).then(items => {
        this.setState({items})
    });
  }

  getExcelThongKe = (ev) => {
    if (!this.validate()) {
      return;
    }
    thongKeService.getExcelThongKeDKQL({fromDate: dayjs(this.state.fromDate).toDate(), toDate: dayjs(this.state.toDate).toDate()}).then(items => {
    });
  }

  componentDidMount() {
    this.getCountDsHetPhep();
  }
  getCountDsHetPhep = () => {
    nhanVienService.countDStTheoDoiNghiPhep().then((rs) =>{
      this.setState({count: rs.count});
    });
  }

  validate = () => {
    if (!dayjs(this.state.fromDate).toDate()) {
      this.setState({errors: {fromDate: "Ngày bắt đầu không hợp lệ"}});
      return false;
    }
    if (!dayjs(this.state.toDate).toDate()) {
      this.setState({errors: {toDate: "Ngày kết thúc không hợp lệ"}});
      return false;
    }

    if (dayjs(this.state.fromDate).toDate() >= dayjs(this.state.toDate).toDate()) {
      this.setState({errors: {fromDate: "Ngày bắt đầu phải nhỏ hơn ngày kết thúc"}});
      return false;
    }
    return true;
  }

  renderRow = (klh) => {
    const congTys = klh.congTys.map(congTy => this.rederDonvi(congTy))
    return(
      <>
        {this.rederDonvi(klh, true)}
        {congTys}
      </>
    )
  }

  showDanhSach = (congTyIds, date, inputType) => {
    thongKeService.getDanhSachQuayLai({congTyIds, date, inputType}).then((dsnv) => {
      if (!dsnv) dsnv = [];
      this.setState({dsnv, openDialog: true});
    });
  }

  rederDonvi(donvi, primary = false) {
    let congTyIds = [donvi.item.id];
    let keyMaps = Object.keys(donvi).filter(q => q.includes('/'));
    return(
    <TableRow className={primary ? 'klh' : ''}>
        <TableCell>{donvi.stt}</TableCell>
        <TableCell className='left'>{donvi.item.name}</TableCell>
        {
            keyMaps.map(key => {
                let keyInfos = key.split(' ');
                let date = dayjs(keyInfos[1], 'DD/MM/YY').format('YYYY-MM-DD');
                let inputType = key.startsWith('Trước') ? 1 : key.startsWith("Sau") ? -1 : 0;
                return(
                    <TableCell key={key}>{
                      donvi[key] === 0 || primary ?
                      donvi[key] :
                      <Button onClick={(ev) => this.showDanhSach(congTyIds, date, inputType)}>
                        {donvi[key]}
                      </Button>
                    }</TableCell>
                )
            })
        }
        <TableCell>{donvi.tong}</TableCell>
    </TableRow>
    )
  }

  renderHeader(data) {
    const countDate = Object.keys(data[0]).filter(q => q.includes('/')).length;
    return(
      <>
        <Dialog maxWidth="lg" open={this.state.openDialog}>
            <DialogContent>
            <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                <TableCell>STT</TableCell>
                  <TableCell>Tên</TableCell>
                  <TableCell>Phòng ban</TableCell>
                  <TableCell>Ngày nghỉ phép</TableCell>
                  <TableCell>Ngày hết phép</TableCell>
                  <TableCell>Ngày hẹn lại</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.dsnv.map((item,index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.TenPhongBan}</TableCell>
                    <TableCell>{dayjs(item.ngayDi).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{dayjs(item.ngayHetPhep).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{dayjs(item.ngayHenLai).isValid() ? dayjs(item.ngayHenLai).format('DD/MM/YYYY') : ""}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={(ev) => {this.setState({openDialog: false})}}>Đóng</Button>
            </DialogActions>
        </Dialog>
        <TableHead>
            <TableRow>
                <TableCell rowSpan={2}>STT</TableCell>
                <TableCell rowSpan={2}>Đơn vị</TableCell>
                <TableCell colSpan={countDate + 1}>KẾ HOẠCH SL NS QUAY LẠI LÀM VIỆC</TableCell>
            </TableRow>
            <TableRow>
                {
                    Object.keys(data[0]).filter(q => q.includes('/')).map(key => {
                        return(
                            <TableCell>{key}</TableCell>
                        )
                    })
                }
                <TableCell>Tổng</TableCell>
            </TableRow>
        </TableHead>
        </>
    );
  }

  showPercent(percent) {
    if (!percent && percent !== 0) return '-';
    let str = percent * 100;
    str = str.toFixed(2) + '%';
    return str;
  }

  render() {
    return (
      <Grid container spacing={2}>
        <Grid
          item
          xs={4}
          className={!this.state.errors.fromDate ? "" : "custom-error-message"}
        >
          <FormControl fullWidth>
            <LocalizationProvider
              sx={{ innerWidth: "100%" }}
              dateAdapter={AdapterDayjs}
            >
              <DatePicker
                label="Ngày bắt đầu"
                inputFormat="DD/MM/YYYY"
                name="fromDate"
                value={dayjs(this.state.fromDate)}
                onChange={(fromDate) => {this.setState({fromDate: dayjs(fromDate).toDate()})}}
                renderInput={(params) => <TextField {...params} />}
              />
              <div className="error-message">{this.state.errors.ngayDi}</div>
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={4}
          className={!this.state.errors.toDate ? "" : "custom-error-message"}
        >
          <FormControl fullWidth>
            <LocalizationProvider
              sx={{ innerWidth: "100%" }}
              dateAdapter={AdapterDayjs}
            >
              <DatePicker
                label="Ngày kết thúc"
                inputFormat="DD/MM/YYYY"
                name="toDate"
                value={dayjs(this.state.toDate)}
                onChange={(toDate) => {this.setState({toDate: dayjs(toDate).toDate()})}}
                renderInput={(params) => <TextField {...params} />}
              />
              <div className="error-message">{this.state.errors.ngayDi}</div>
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" onClick={this.getThongKe}>Tìm kiếm</Button>
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" onClick={this.getExcelThongKe}>Tải báo cáo</Button>
        </Grid>
        <Grid item xs={12}>
          <h4 className='warning'>
          {this.state.count > 0 ?"Yêu cầu nhập đầy đủ thông theo dõi nghỉ phép trước khi tải báo cáo": " "}
          </h4>
            {
                this.state.items.length === 0 ?
                    <h4> Không có dữ liệu </h4>
                :
                <TableContainer component={Paper}>
                    <Table
                      className='thongke'
                      sx={{ minWidth: 650 }}>
                        {this.renderHeader(this.state.items)}
                        <TableBody>
                            {this.state.items.map((item) => (
                            this.renderRow(item)
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Grid>
      </Grid>
    );
  }
}

export default TKDKQLPage;