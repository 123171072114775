import dayjs from "dayjs";

const validateDate = function(date) {
    if (date instanceof Date && date.getDate()) {
        return true;
    }
    return false;
}

const validateHoChieu = function(hoChieu) {
    let regx = [/^[A-Z]{1}[0-9]{7,9}$/];
    if (!hoChieu) {
        return true;
    } else {
        if(hoChieu.match(regx)) {
            return true;
        }
        return false;
    }
}

const rules = [
    // Tên
    {
      field: 'name',
      method: 'isEmpty',
      validWhen: false,
      message: 'Vui lòng nhập.',
    },
    {
      field: 'name',
      method: 'isLength',
      args: [{min: 1}],
      validWhen: true,
      message: 'Vui lòng nhập ít nhất 1 kí tự',
    },
    // Ngày sinh
    {
        field: 'ngaySinh',
        method: validateDate,
        validWhen: true,
        message: 'Ngày sinh không hợp lệ',
    },
    {
        field: 'ngaySinh',
        method: function(date) {
            if (!date) return true;
            let validateDate = new Date(date.getTime());
            validateDate.setDate(validateDate.getDate() + 18 * 365.25);
            if (validateDate.getTime() > (new Date()).getTime()) {
                return false;
            }
            if ((date.getYear() + 1900) < 1950) {
                return false;
            }
            return true;
        },
        validWhen: true,
        message: 'Nhân viên phải đủ 18 tuổi và không quá 80 tuổi',
    },
    // Ngày vào làm
    {
        field: 'ngayVaoLam',
        method: validateDate,
        validWhen: true,
        message: 'Ngày vào làm không hợp lệ',
    },
    {
        field: 'ngayVaoLam',
        method: function(date) {
            if (!date) return true;
            if ((date.getYear() + 1900) < 1990) {
                return false;
            }
            return true;
        },
        validWhen: true,
        message: 'Ngày vào làm không hợp lệ',
    },
    {
        field: 'PhongBanId',
        method: function(PhongBanId) {
            if (!PhongBanId) {
                return false;
            }
            return true;
        },
        validWhen: true,
        message: 'Vui lòng nhập phòng ban',
    },
    {
        field: 'ngayHetHan',
        method: validateDate,
        validWhen: true,
        message: 'Ngày vào làm không hợp lệ',
    },

    {
      field: 'maNV',
      method: 'isEmpty',
      validWhen: false,
      message: 'Vui lòng nhập.',
    },
    {
        field: 'maNV',
        method: 'matches',
        args: [/^[0-9]{7,9}$/],
        validWhen: true,
        message: 'Mã nhân viên phải có từ 7 đến 9 kí tự số',
    },

    {
        field: 'hoChieu',
        method: function(hc, regx) {
            if (!hc) {
                return true;
            } else {
                if(hc.match(regx)) {
                    return true;
                }
                return false;
            }
        },
        args: [/^[A-Z]{1}[0-9]{7,9}$/],
        validWhen: true,
        message: 'Hộ chiếu không đúng định dạng',
    },

    // {
    //     field: 'visaLaoDong',
    //     method: 'matches',
    //     args: [/^[0-9]{11}$/],
    //     validWhen: true,
    //     message: 'Visa không đúng định dạng',
    // },

    {
        field: 'dienThoai',
        method: 'isEmpty',
        validWhen: false,
        message: 'Vui lòng nhập.',
    },
    // {
    //     field: 'dienThoai',
    //     method: 'matches',
    //     args: [/^[0-9]{10,15}$/],
    //     validWhen: true,
    //     message: 'Mã nhân viên phải có từ 10 đến 15 kí tự số',
    // },

    {
        field: 'diaChi',
        method: 'isEmpty',
        validWhen: false,
        message: 'Vui lòng nhập địa chỉ.',
    },
    {
        field: 'diaChi',
        method: 'isLength',
        args: [{min: 20, max: 500}],
        validWhen: true,
        message: 'Địa chỉ phải có ít nhất 20 kí tự',
    },
    {
        field: 'diaChi',
        method: 'isLength',
        args: [{min: 20, max: 500}],
        validWhen: true,
        message: 'Địa chỉ phải có ít nhất 20 kí tự',
    }
]

export default rules;