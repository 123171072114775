import React, { Component } from 'react';
import Button from "@mui/material/Button";
import PhongBanDialog from "./PhongBanDialog";
import PhongBanItem from './PhongBanItem';
import PhongBanService from "../../services/phongBanService";

import { LoaiPhongBans } from '../../utils/cons';
import PhongBanTree from './PhongBanTree';
const phongBanService = new PhongBanService();
const capBac = LoaiPhongBans;

class PhongBanPage extends Component {
  state = {
    tree: [],
    openDialog: false,
    updateModel: {name: "", maHRM: "", loai: capBac[0]}
  }
  renderTreeView = (treeItem) => {
    let id = treeItem.id + "";
    return (
      <PhongBanItem
        hasCheckBox={true}
        key={id}
        nodeId={id}
        label={treeItem.name}
        item={treeItem}
        onAddClick={this.clickAdd}
        onEditClick={this.clickEdit}
        onDeleteClick={this.clickDelete}>
        {treeItem.child ? treeItem.child.map((childT) => this.renderTreeView(childT)) : ""}
      </PhongBanItem>);
  }
  addKLH = (ev) => {
    this.setState({
      openDialog : true,
      updateModel: {
        name : "",
        maHRM: "",
        loai: capBac[0]}
      });
  };
  handleClickClose = (ev, rs) => {
    if (rs !== "backdropClick") {
      this.setState({openDialog : false});
    }
  }
  reloadTree = () => {
    phongBanService.getTree().then(tree => {
      this.setState({tree, openDialog: false});
    });
  }
  componentDidMount = () => {
    this.reloadTree();
  }
  // on click add item
  clickAdd = (ev, item) => {
    const loai = item.loai == capBac[0] ? capBac[1] : capBac[2];
    ev.stopPropagation();
    this.setState({
      updateModel:
      {
        name : "",
        maHRM: "",
        PhongBanId: item.id,
        loai
      },
      openDialog: true
    });
  }
  // on click edit item
  clickEdit = (ev, item) => {
    ev.stopPropagation();
    this.setState({updateModel : item, openDialog: true});
  }
  // on click delete item
  clickDelete = (ev, item) => {
    ev.stopPropagation();
  }

  // Đồng bộ dữ liệu
  sync = (ev) => {
    phongBanService.sync().then(tree => {
      this.setState({tree, openDialog: false});
    });
  }

  render() { 
    return (
    <div>
        <Button onClick={this.addKLH}>Thêm mới khu liên hợp</Button>
        <PhongBanDialog
          open={this.state.openDialog}
          onClose={this.handleClickClose}
          model={this.state.updateModel}
          service={phongBanService}
          onCreated={this.reloadTree}/>
        <PhongBanTree
          width="400px"
          hasCheckBox={false}
          tree={this.state.tree}
          onItemAddClick={this.clickAdd}
          onItemEditClick={this.clickEdit}
          onItemDeleteClick={this.clickDelete}
          service={phongBanService}
        />
        <Button onClick={this.sync}>Đồng bộ phòng ban</Button>
    </div>);
  }
}
 
export default PhongBanPage;