import './thongke.css'
import { Button, Dialog, DialogActions, DialogContent, FormControl, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { Component } from "react";
import ThongkeService from '../../services/thongkeService';
import NhanVienService from '../../services/nhanvienService';
const thongKeService = new ThongkeService();
const nhanVienService = new NhanVienService();

class TKBDNSPage extends Component {
  state = {
    fromDate: (new Date()).setDate(new Date().getDate() - new Date().getDate()),
    toDate: new Date(),
    dsnv: [],
    items: [],
    errors: {},
    openDialog: false,
    hienQuayLai: false,
    hienNgayNghi: false,
    hienNgayQL: false,
    count: 0,
  };

  getThongKe = (ev) => {
    this.getCountDsHetPhep();
    if (!this.validate()) {
      return;
    }
    thongKeService.getThongKe({fromDate: dayjs(this.state.fromDate).format("YYYY-MM-DD"), toDate: dayjs(this.state.toDate).format("YYYY-MM-DD")}).then(items => {
        this.setState({items})
    });
  }

  getExcelThongKe = (ev) => {
    this.getCountDsHetPhep();
    if (!this.validate()) {
      return;
    }
    thongKeService.getExcelThongKe({fromDate: dayjs(this.state.fromDate).toDate(), toDate: dayjs(this.state.toDate).toDate()}).then(items => {
    });
  }

  validate = () => {
    if (!dayjs(this.state.fromDate).toDate()) {
      this.setState({errors: {fromDate: "Ngày bắt đầu không hợp lệ"}});
      return false;
    }
    if (!dayjs(this.state.toDate).toDate()) {
      this.setState({errors: {toDate: "Ngày kết thúc không hợp lệ"}});
      return false;
    }

    if (dayjs(this.state.fromDate).toDate() >= dayjs(this.state.toDate).toDate()) {
      this.setState({errors: {fromDate: "Ngày bắt đầu phải nhỏ hơn ngày kết thúc"}});
      return false;
    }
    return true;
  }

  renderRow = (klh) => {
    const congTys = klh.congTys.map(congTy => this.rederDonvi(congTy))
    return(
      <>
        {this.rederDonvi(klh, true)}
        {congTys}
      </>
    )
  }
  layDanhSachNSM(congTyId, hienQuayLai,hienNgayNghi, hienNgayQL) {
    let query = {
      congTyId,
      fromDate: dayjs(this.state.fromDate).format('YYYY-MM-DD'),
      toDate: dayjs(this.state.toDate).format('YYYY-MM-DD')
    };
    nhanVienService.getDsNhanSuMoi(query).then((dsnv) => {
      this.setState({dsnv, openDialog: true, hienQuayLai, hienNgayNghi, hienNgayQL})
    });
  }

  layDanhSach(congTyId, hienQuayLai,hienNgayNghi, hienNgayQL) {
    let query = {
      congTyId,
      toDates: dayjs(this.state.toDate).format('YYYY-MM-DD'),
      toDate: dayjs(this.state.toDate).format('YYYY-MM-DD')
    };
    nhanVienService.getDsDangNghiPhep(query).then((dsnv) => {
      this.setState({dsnv, openDialog: true, hienQuayLai, hienNgayNghi, hienNgayQL})
    });
  }
  layDanhSachNPQL(congTyId,hienQuayLai,hienNgayNghi, hienNgayQL) {
    let query = {
      congTyId,
      fromDate: dayjs(this.state.fromDate).format('YYYY-MM-DD'),
      toDate: dayjs(this.state.toDate).format('YYYY-MM-DD')
    };
    nhanVienService.getDsNPQuayLai(query).then((dsnv) =>{
      this.setState({dsnv, openDialog:true, hienQuayLai, hienNgayNghi, hienNgayQL})
    });
  }
  layDanhSachNPCQL(congTyId,hienQuayLai,hienNgayNghi, hienNgayQL) {
    let query = {
      congTyId,
      toDate: dayjs(this.state.toDate).format('YYYY-MM-DD'),
      fromDate: dayjs(this.state.fromDate).format('YYYY-MM-DD')
    };
    nhanVienService.getDsNPChuaQuayLai(query).then((dsnv) => {
      this.setState({dsnv, openDialog: true, hienQuayLai, hienNgayNghi, hienNgayQL})
    });
  }
  layDanhSachNSDKNV(congTyId, hienQuayLai, hienNgayNghi, hienNgayQL) {
    let query = {
      congTyId,
      toDate: dayjs(this.state.toDate).format('YYYY-MM-DD'),
      fromDate: dayjs(this.state.fromDate).format('YYYY-MM-DD')
    };
    nhanVienService.getDsNSDKNghiViec(query).then((dsnv) =>{
      this.setState({dsnv, openDialog:true, hienQuayLai, hienNgayNghi, hienNgayQL})
    });
  }
  layDanhSachNSNV(congTyId,hienQuayLai,hienNgayNghi, hienNgayQL) {
    let query = {
      congTyId,
      toDate: dayjs(this.state.toDate).format('YYYY-MM-DD'),
      fromDate: dayjs(this.state.fromDate).format('YYYY-MM-DD')
    };
    nhanVienService.getDsNSNghiViec(query).then((dsnv) =>{
      this.setState({dsnv, openDialog:true, hienQuayLai, hienNgayNghi, hienNgayQL})
    });
  }
  componentDidMount() {
    this.getCountDsHetPhep();
  }
  getCountDsHetPhep = () => {
    nhanVienService.countDStTheoDoiNghiPhep().then((rs) =>{
      this.setState({count: rs.count});
    });
  }
  rederDonvi(donvi, primary = false) {
    return(
    <TableRow key={donvi.name} className={primary ? 'klh' : ''}>
      <TableCell>{donvi.stt}</TableCell>
      <TableCell className='left'>{donvi.item.name}</TableCell>
      <TableCell>{donvi.SLHienHuu}</TableCell>
      <TableCell>
      {
          donvi.SLTuyenMoi <= 0 || primary ? 
          donvi.SLTuyenMoi
          : <Button onClick={() => this.layDanhSachNSM(donvi.item.id,false, false, false)}>{donvi.SLTuyenMoi}</Button>
        }
      </TableCell>      
      <TableCell>
      {
          donvi.SLNghiPhepQL <= 0 || primary ? 
          donvi.SLNghiPhepQL
          : <Button onClick={() => this.layDanhSachNPQL(donvi.item.id,true, false, true)}>{donvi.SLNghiPhepQL}</Button>
        }
      </TableCell>
      <TableCell>{this.showPercent(donvi.ptNghiPhepQL)}</TableCell>

      <TableCell>
        {
          donvi.SLDangNghi <= 0 || primary ? 
          donvi.SLDangNghi
          : <Button onClick={() => this.layDanhSach(donvi.item.id,true, false, false)}>{donvi.SLDangNghi}</Button>
        }
      </TableCell>
      <TableCell>{this.showPercent(donvi.ptDangNghi)}</TableCell>

      <TableCell>
      {
          donvi.SLNghiPhepCQL <= 0 || primary ? 
          donvi.SLNghiPhepCQL
          : <Button onClick={() => this.layDanhSachNPCQL(donvi.item.id, false, false, false)}>{donvi.SLNghiPhepCQL}</Button>
        }
      </TableCell>
      <TableCell>{this.showPercent(donvi.ptNghiPhepCQL)}</TableCell>

      <TableCell>{donvi.SLTongCong}</TableCell>
      <TableCell>{this.showPercent(donvi.ptTongCong)}</TableCell>
  
      <TableCell>
      {
          donvi.SLDuKienNghi <= 0 || primary ? 
          donvi.SLDuKienNghi
          : <Button onClick={() => this.layDanhSachNSDKNV(donvi.item.id,true, true, false)}>{donvi.SLDuKienNghi}</Button>
        }
      </TableCell>
      <TableCell>{this.showPercent(donvi.ptNghiViec)}</TableCell>

      <TableCell>
      {
          donvi.SLNghiViec <= 0 || primary ? 
          donvi.SLNghiViec
          : <Button onClick={() => this.layDanhSachNSNV(donvi.item.id,true, true, false)}>{donvi.SLNghiViec}</Button>
        }
      </TableCell>
      <TableCell>{this.showPercent(donvi.ptNghiViec)}</TableCell>
    </TableRow>
    )
  }

  showPercent(percent) {
    if (!percent && percent !== 0) return '-';
    let str = percent * 100;
    str = str.toFixed(2) + '%';
    return str;
  }

  render() {
    return (
      <>
      <Dialog maxWidth="lg" open={this.state.openDialog}>
          <DialogContent>
          <TableContainer>
          <Table aria-label="simple table">
            <TableHead >
              <TableRow>
              <TableCell>STT</TableCell>
                <TableCell>Tên</TableCell>
                <TableCell>Phòng ban</TableCell>
                <TableCell>Ngày vào làm</TableCell>
                <TableCell>Ngày nghỉ phép</TableCell>
                <TableCell>Ngày hết phép</TableCell>
                {
                  this.state.hienQuayLai?<TableCell>Ngày hẹn lại</TableCell>:" "
                }
                {
                  this.state.hienNgayQL?<TableCell>Ngày quay lại</TableCell>:" "
                }
                {
                  this.state.hienNgayNghi?<TableCell>Ngày nghỉ việc</TableCell>:" "
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.dsnv.map((item,index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.TenPhongBan}</TableCell>
                  <TableCell>{dayjs(item.ngayVaoLam).format('DD/MM/YYYY')}</TableCell>
                  
                  <TableCell>{dayjs(item.ngayDi).isValid() ?dayjs(item.ngayDi).format('DD/MM/YYYY'):""}</TableCell>
                  <TableCell>{dayjs(item.ngayHetPhep).isValid() ?dayjs(item.ngayHetPhep).format('DD/MM/YYYY'):""}</TableCell>
                  {
                    this.state.hienQuayLai?<TableCell>{dayjs(item.ngayHenLai).isValid() ? dayjs(item.ngayHenLai).format('DD/MM/YYYY'):""}</TableCell>:" "
                  }
                  {
                    this.state.hienNgayQL?<TableCell>{dayjs(item.ngayQuayLai).isValid() ? dayjs(item.ngayQuayLai).format('DD/MM/YYYY'):""}</TableCell>:" "
                  }
                  {
                    this.state.hienNgayNghi?<TableCell>{dayjs(item.ngayNghiViec).isValid() ? dayjs(item.ngayNghiViec).format('DD/MM/YYYY'):""}</TableCell>:" "
                  }
                </TableRow>

              ))}
            </TableBody>
          </Table>
        </TableContainer>
          </DialogContent>
          <DialogActions>
              <Button onClick={(ev) => {this.setState({openDialog: false})}}>Đóng</Button>
          </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        <Grid
          item
          xs={4}
          className={!this.state.errors.fromDate ? "" : "custom-error-message"}
        >
          <FormControl fullWidth>
            <LocalizationProvider
              sx={{ innerWidth: "100%" }}
              dateAdapter={AdapterDayjs}
            >
              <DatePicker
                label="Ngày bắt đầu"
                inputFormat="DD/MM/YYYY"
                name="fromDate"
                value={dayjs(this.state.fromDate)}
                onChange={(fromDate) => {this.setState({fromDate: dayjs(fromDate).toDate()})}}
                renderInput={(params) => <TextField {...params} />}
              />
              <div className="error-message">{this.state.errors.ngayDi}</div>
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={4}
          className={!this.state.errors.toDate ? "" : "custom-error-message"}
        >
          <FormControl fullWidth>
            <LocalizationProvider
              sx={{ innerWidth: "100%" }}
              dateAdapter={AdapterDayjs}
            >
              <DatePicker
                label="Ngày kết thúc"
                inputFormat="DD/MM/YYYY"
                name="toDate"
                value={dayjs(this.state.toDate)}
                onChange={(toDate) => {this.setState({toDate: dayjs(toDate).toDate()})}}
                renderInput={(params) => <TextField {...params} />}
              />
              <div className="error-message">{this.state.errors.ngayDi}</div>
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" onClick={this.getThongKe}>Tìm kiếm</Button>
        </Grid>
        
        <Grid item xs={2}>
        <Button variant="contained" onClick={this.getExcelThongKe}>Tải báo cáo</Button>
          
        </Grid>
        <Grid item xs={12}>
              <h4 className='warning'>
              {this.state.count > 0 ?"Yêu cầu nhập đầy đủ thông theo dõi nghỉ phép trước khi tải báo cáo": " "}
              </h4>
            {
            
                this.state.items.length === 0 ?
                    <h4> Không có dữ liệu </h4>
                :
                <TableContainer component={Paper}>
                    <Table
                      className='thongke'
                      sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                        <TableCell rowSpan={3}>STT</TableCell>
                        <TableCell rowSpan={3}>Đơn vị</TableCell>
                        <TableCell rowSpan={3}>Số lượng nhân sự VN đến {dayjs(this.state.toDate).format('DD/MM/YYYY')}</TableCell>
                        <TableCell rowSpan={3}>Tuyển mới từ ngày {dayjs(this.state.fromDate).format('DD/MM/YYYY')} đến ngày {dayjs(this.state.toDate).format('DD/MM/YYYY')}</TableCell>
                        <TableCell colSpan={8}>Nhân sự có nghỉ phép/ tạm ngừng việc</TableCell>
                        <TableCell colSpan={2} rowSpan={2}>
                            Nhân sự dự kiến nghỉ việc
                        </TableCell>
                        <TableCell colSpan={2} rowSpan={2}>
                            Nhân sự chấm dứt HĐLĐ
                        </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}>SL Nhân sự nghỉ phép và đã quay lại làm việc</TableCell>
                            <TableCell colSpan={2}>SL Nhân sự đang trong thời gian nghỉ phép</TableCell>
                            <TableCell colSpan={2}>SL Nhân sự hết phép & chưa quay lại làm việc</TableCell>
                            <TableCell colSpan={2}>Tổng cộng</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Số lượng</TableCell>
                            <TableCell>Tỷ lệ</TableCell>

                            <TableCell>Số lượng</TableCell>
                            <TableCell>Tỷ lệ</TableCell>

                            <TableCell>Số lượng</TableCell>
                            <TableCell>Tỷ lệ</TableCell>

                            <TableCell>Số lượng</TableCell>
                            <TableCell>Tỷ lệ</TableCell>

                            <TableCell>Số lượng</TableCell>
                            <TableCell>Tỷ lệ</TableCell>

                            <TableCell>Số lượng</TableCell>
                            <TableCell>Tỷ lệ</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.items.map((item) => (
                          this.renderRow(item)
                        ))}
                    </TableBody>
                    </Table>
                </TableContainer>
            }
        </Grid>
      </Grid>
      </>
    );
  }
}

export default TKBDNSPage;
