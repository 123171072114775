import { FormControl, FormHelperText, Grid, InputLabel, TextField, NativeSelect } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { DeXuatHenQuayLai } from '../../utils/cons';

class HenQuayLaiEditView extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        lyDos: [],
        errors: {},
        model: this.props.model,
        open: false,
    }

    validate() {
        let error = null;
        if (!this.state.model.ngayHenLai) {
            error = "Phải nhập ngày hẹn lại";
        }
        if (this.props.maxHenLai) {
            if (dayjs(this.state.model.ngayHenLai).toDate() < dayjs(this.props.maxHenLai).toDate()) {
                error = "Ngày hẹn lại phải lớn hơn ngày hết phép hay ngày hẹn lại gần nhất " + dayjs(this.props.maxHenLai).format("DD/MM/YYYY");
            }
        }
        if (this.props.minHenLai) {
            if (dayjs(this.state.model.ngayHenLai).toDate() >= dayjs(this.props.minHenLai).toDate()) {
                error = "Ngày hẹn lại phải bé hơn hẹn lại trước đó " + dayjs(this.props.minHenLai).format('DD/MM/YYYY');
            }
        }
        return error;
    }

    handleChange = (value, name) => {
        let model = this.state.model;
        model[name] = value
        this.setState({model});
        let ngayHenLai = this.validate();
        this.setState({errors: {ngayHenLai}});
        if (this.props.handleChange) {
            this.props.handleChange(model, ngayHenLai);
        }
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (nextProps.open === true && this.props.open === false) {
            let model = Object.assign({}, nextProps.model);
            this.setState({model, errors: {}});
            return true;
        }
        return true;
    }

    render() { 
        return (
        <Grid item container spacing={1}>
            <Grid item xs={6} className={!this.state.errors.ngayHenLai ? "" : "custom-error-message"}>
              <FormControl fullWidth>
                <LocalizationProvider
                  sx={{innerWidth: "100%"}} dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Ngày hẹn lại"
                        inputFormat="DD/MM/YYYY"
                        value={dayjs(this.state.model.ngayHenLai)}
                        onChange={(ev) => this.handleChange(ev, "ngayHenLai")}
                        renderInput={(params) => <TextField {...params} />}
                    />
                  <div className='error-message'>{this.state.errors.ngayHenLai}</div>
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="ly_do_hen_lai_select">
                        Lý do hẹn lại
                    </InputLabel>
                    <NativeSelect
                        size='medium'
                        onChange={(ev) => this.handleChange(ev.target.value, "LyDoId")}
                        value={this.state.model.LyDoId}
                        inputProps={{
                        name: 'LyDoId',
                        id: 'ly_do_hen_lai_select',
                        }}>
                        {this.props.lyDos.map((lydo, i) => (
                            <option key={lydo.id} value={lydo.id}>{lydo.name}</option>
                        ))}
                    </NativeSelect>
                    <FormHelperText style={{color: 'red'}}>
                        {this.state.errors.LyDoId}
                    </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="de_xuat_select">
                        Đề xuất xử lý
                    </InputLabel>
                    <NativeSelect
                        value={this.state.model.deXuat}
                        onChange={(ev) => this.handleChange(ev.target.value, "deXuat")}
                        inputProps={{
                        name: 'deXuat',
                        id: 'de_xuat_select',
                        }}>
                        {DeXuatHenQuayLai.map((tt, i) => (
                            <option key={i} value={tt}>{tt}</option>
                        ))}
                    </NativeSelect>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                    <TextField
                        id="ghiChu"
                        name='ghiChu'
                        label="Ghi Chú"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={this.state.model.ghiChu}
                        rows={4}
                        maxRows={4}
                        onChange={(ev) => this.handleChange(ev.target.value, ev.target.name)}
                    />
              </FormControl>
            </Grid>
        </Grid>);
    }
}
 
export default HenQuayLaiEditView;