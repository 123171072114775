import methods from "validator";
import dayjs from "dayjs";

const validateDate = function(date) {
  date = dayjs(date);
  if (date.toDate) {
    date = date.toDate();
  }
  if (date instanceof Date && date.getDate()) {
      return true;
  }
  return false;
}

class Validator {
  constructor(rules) {
    this.rules = rules;
    this.initiate();
  }

  initiate() {
    this.isValid = true;
    this.errors = {};
  }

  validate(state) {
    this.initiate();
    this.rules.forEach((rule) => {
      if (this.errors[rule.field]) return;

      const fieldValue = state[rule.field] || "";
      const args = rule.args || [];
      let validationMethod =
        typeof rule.method === "string" ? methods[rule.method] : rule.method;
      if (rule.method === "validateDate") {
        validationMethod = validateDate;
      }
      let result = validationMethod(fieldValue, ...args, state);

      if (rule.message === undefined) {
        if (result) {
          this.errors[rule.field] = result;
          this.isValid = false;
        }
        return;
      }

      if (result !== rule.validWhen) {
        this.errors[rule.field] = rule.message;
        this.isValid = false;
      }
    });
    return this.errors;
  }
}

export default Validator;
