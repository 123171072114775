import React, { Component } from 'react';
import { Button, Grid, TextField, Box, Tab, GlobalStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import VePhepService from '../../services/vePhepService';
import dayjs from 'dayjs';

const columns = [
    { field: 'Mã nhân viên', headerName: 'Mã nhân viên', width: 100 },
    { field: 'Tên nhân viên', headerName: 'Tên nhân viên', width: 130 },

    { field: 'Ngày đi', headerName: 'Ngày đi', width: 130, valueFormatter: params => params?.value ? dayjs(params?.value).format("DD/MM/YYYY") : ""},
    { field: 'Ngày hết phép', headerName: 'Ngày hết phép', width: 130, valueFormatter: params => params?.value ? dayjs(params?.value).format("DD/MM/YYYY") : ""},

    { field: 'Cửa khẩu', headerName: 'Cửa khẩu', width: 130 },
    { field: 'Lý do', headerName: 'Lý do', width: 130 },
    { field: 'Trạng thái', headerName: 'Trạng thái', width: 130 },

    { field: 'Ngày quay lại làm việc', headerName: 'Ngày quay lại làm việc', valueFormatter: params => params?.value ? dayjs(params?.value).format("DD/MM/YYYY") : "" },
    { field: 'Ngày nghỉ việc', headerName: 'Ngày nghỉ việc', valueFormatter: params => params?.value ? dayjs(params?.value).format("DD/MM/YYYY") : ""},

    { field: 'Ngày hẹn lại lần 1', headerName: 'Ngày hẹn lại lần 1', width: 130, valueFormatter: params => params?.value ? dayjs(params?.value).format("DD/MM/YYYY") : ""},
    { field: 'Lý do hẹn lại lần 1', headerName: 'Lý do hẹn lại lần 1', width: 130},
    { field: 'Đề xuất xử lý lần 1', headerName: 'Đề xuất xử lý lần 1', width: 130},
    { field: 'Ghi chú lần 1', headerName: 'Ghi chú lần 1', width: 130},

    { field: 'Ngày hẹn lại lần 2', headerName: 'Ngày hẹn lại lần 2', width: 130, valueFormatter: params => params?.value ? dayjs(params?.value).format("DD/MM/YYYY") : ""},
    { field: 'Lý do hẹn lại lần 2', headerName: 'Lý do hẹn lại lần 2', width: 130},
    { field: 'Đề xuất xử lý lần 2', headerName: 'Đề xuất xử lý lần 2', width: 130},
    { field: 'Ghi chú lần 2', headerName: 'Ghi chú lần 2', width: 130},

    { field: 'Ngày hẹn lại lần 3', headerName: 'Ngày hẹn lại lần 3', width: 130, valueFormatter: params => params?.value ? dayjs(params?.value).format("DD/MM/YYYY") : ""},
    { field: 'Lý do hẹn lại lần 3', headerName: 'Lý do hẹn lại lần 3', width: 130},
    { field: 'Đề xuất xử lý lần 3', headerName: 'Đề xuất xử lý lần 3', width: 130},
    { field: 'Ghi chú lần 3', headerName: 'Ghi chú lần 3', width: 130},

    { 
        field: 'error',
        headerName: 'Lỗi',
        valueGetter : (params) => {
            return params.row.errors.map(q => q.message).join(", ");
        },
        width: 400
    },
];

class ImportFile extends Component {
    constructor(props) {
        super(props);
        this.vePhepService = new VePhepService();
    }

    state = { validData : [], inValidData: [], tabIndex: "1", openDialog: false }
    
    processingFile = (e) => {
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            data = XLSX.utils.sheet_to_json(ws, {header:0});

            let validData = [];
            let inValidData = [];
            this.vePhepService.validateImport(data).then((rsData) => {
                for (let row of rsData) {
                    if (row.valid) {
                        validData.push(row);
                    } else {
                        inValidData.push(row);
                    }
                }
                let tabIndex = this.state.tabIndex;
                if (inValidData.length > 0) {
                    tabIndex = "2";
                }
                this.setState({validData, inValidData, tabIndex});
            })
        };
        reader.readAsBinaryString(f);
    }

    importBath = (ev) => {
        this.vePhepService.importBath(this.state.validData)
        .then((rs) => {
            this.setState({alertMessage: `Đã thêm thành công ${rs.count} về phép`, openDialog: true})
        })
        .catch((ex) => {
            this.setState({alertMessage: ex.message, openDialog: true})
        });
    }

    render() { 
        return (
            <>
            <Dialog
                open={this.state.openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">
                {"Thông báo"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {this.state.alertMessage}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(ev) => this.setState({openDialog: false})} autoFocus>
                        Đóng
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container>
                <GlobalStyles styles={{ '.error-cell': { backgroundColor: 'red' } }}></GlobalStyles>
                <Grid item xs={9}>
                    <TextField type="text" variant="outlined" size="small" style={{width: "100%"}}></TextField>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        variant="contained"
                        component="label"
                    >
                        Upload File
                        <input type="file" onChange={this.processingFile} style={{width: "0px"}} />
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <TabContext value={this.state.tabIndex}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={(ev, tabIndex) => {this.setState({tabIndex})}} aria-label="Thông tin nhân viên">
                                <Tab label="Dữ liệu đúng" value="1" />
                                <Tab label="Dữ liệu sai" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1" sx={{padding: "0px !important"}}>
                            <Box sx={{ height: "60vh", width: '100%' }}>
                                <DataGrid
                                    rows={this.state.validData}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    experimentalFeatures={{ newEditingApi: true }}
                                />
                            </Box>
                            <Button variant='contained' onClick={this.importBath} disabled={this.state.validData.length == 0} sx={{float: 'right', marginTop: '1rem'}}>Import</Button>
                        </TabPanel>
                        <TabPanel value="2" sx={{padding: "0px !important"}}>
                            <Box sx={{ height: "60vh", width: '100%' }}>
                                <DataGrid
                                    rows={this.state.inValidData}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    disableSelectionOnClick
                                    experimentalFeatures={{ newEditingApi: true }}
                                    getCellClassName={(params) => {
                                        let errorNames = params.row.errors.map(q => q.prop);
                                        if (errorNames.includes(params.field)) {
                                          return 'error-cell';
                                        } else {
                                            return '';
                                        }
                                    }}
                                />
                            </Box>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
            </>
        );
    }
}
 
export default ImportFile;