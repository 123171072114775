import ABSService from "./absService";
import requestHelper from "../utils/requestHelper";

class VePhepService extends ABSService {
    constructor() {
        super("vephep");
    }

    validateImport = async(data) => {
        return await requestHelper.post('/api/vephep/validate', data);
    }

    importBath = async(data) => {
        return await requestHelper.post('/api/vephep/import', data);
    }

    create = async(data) => {
        let newDate = await super.create(data);
        
    }
}

export default VePhepService;