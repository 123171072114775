import { Add, Delete, Edit } from '@mui/icons-material';
import { TableCell, Button, TableContainer, Paper, Table, TableHead, TableRow, TableBody, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid } from '@mui/material';
import { Stack } from '@mui/system';
import React, { Component } from 'react';
import dayjs from 'dayjs';
import HenQuayLaiEditView from './HenQuayLaiEditView';
import {DeXuatHenQuayLai} from '../../utils/cons'

class HenQuayLaiPage extends Component {
  constructor(props) {
    super(props);
    this.vePhepId = props.vePhepId;
    this.service = props.service;
  }
  state = {
    items: [],
    item: {},
    open: false,
    openDeleteDialog: false,
    open: false,
    insertMode: true,
    maxHenLai: new Date(),
    minHenLai: new Date()
  }
  onClose = () => {
    this.setState({open: false});
  }
  openAddDialog = () => {
    const ngayHenLai = this.state.items[0] ? this.state.items[0].ngayHenLai : new Date();
    this.setState({item: {ngayHenLai, VePhepId: this.vePhepId},open: true});
  }

  handleDelete = (ev, rs) => {
    const deleteId = this.state.item.id;
    this.service.delete(deleteId).then((rs) => {
      this.props.reload();
    });
    this.setState({ openDeleteDialog: false });
  };

  newHenVePhepModel() {
    const henVePhep = {
      id: -1,
      ngayHenLai: new Date(),
      kichHoat: 1,
      LyDoId : this.state.lyDos.length > 0 ? this.state.lyDos[0].id : null,
      deXuat: DeXuatHenQuayLai[0],
    }
    return henVePhep;
  }

  setMaxHenLai = (insertMode, index) => {
    let minHenLai = null;

    // Cài đặt ngày đi tối đa là ngày hẹn lại gần nhất
    let vePhepIndex = this.props.vePheps.reduce((pr, cu, index, a) => {
      if (cu.id == this.props.vePhepId) {
        pr = index;
      }
      return pr;
    }, -1);
    if (vePhepIndex > 0) {
      minHenLai = this.props.vePheps[vePhepIndex - 1].ngayDi;
    }

    let maxHenLai = null;
    if (!insertMode) {
      if (this.props.items.length === 1) {
        maxHenLai = dayjs(this.props.vePhep.ngayHetPhep).toDate();
      } else {
        if (index === 0) {
          maxHenLai = dayjs(this.props.items[1].ngayHenLai).toDate();
        } 
        if (index === this.props.items.length - 1) {
          maxHenLai = dayjs(this.props.vePhep.ngayHetPhep).toDate();
        }
        if (index > 0) {
          minHenLai = dayjs(this.props.items[index - 1].ngayHenLai).toDate();
        }
      }
    } else {
      maxHenLai = dayjs(this.props.items[0].ngayHenLai).toDate();
    }
    maxHenLai = dayjs(maxHenLai).toDate();
    maxHenLai.setDate(maxHenLai.getDate() + 1);
    this.setState({maxHenLai, minHenLai});
    return maxHenLai;
  }

  onChangeItem = (item, error) => {
    this.editItem = item;
    this.error = error;
  }

  save = () => {
    if (this.error) {
      return;
    }
    if (this.state.insertMode) {
      this.service.create(this.editItem).then((rs) => {
        this.props.reload();
        this.setState({open: false});
      });
    } else {
      this.service.update(this.editItem).then((rs) => {
        this.props.reload();
        this.setState({open: false});
      });
    }
  }

  openAddDialog = () => {
    let ngayHenLai = this.setMaxHenLai(true);
    let LyDoId = this.props.lyDos.length > 0 ? this.props.lyDos[0].id : null;
    const item = {
      id: -1,
      ngayHenLai,
      kichHoat: 1,
      LyDoId,
      deXuat: DeXuatHenQuayLai[0],
      VePhepId: this.props.vePhep.id
    }
    this.editItem = item;
    this.setState({item, open: true, insertMode: true});
  }

  // on click edit item
  openEditDialog = (ev, item, index) => {
    this.setMaxHenLai(false, index);
    this.setState({ item, open: true, insertMode : false });
    this.onChangeItem(item, null);
  };

  render() { 
    return (
    <Stack>
      <Dialog
        open={this.state.openDeleteDialog}
        keepMounted
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{"Cảnh báo"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Bạn có chắc chắn muốn xóa
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({openDeleteDialog: false})}>Đóng</Button>
          <Button onClick={this.handleDelete}>Đồng ý</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth={true}
        open={this.state.open}
        keepMounted
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>Chỉnh sửa hẹn quay lại</DialogTitle>
        <DialogContent>
          <Grid sx={{padding: "1rem"}}>
            <HenQuayLaiEditView
              open={this.state.open}
              lyDos={this.props.lyDos}
              model={this.state.item}
              handleChange={this.onChangeItem}
              maxHenLai={this.state.maxHenLai}
              minHenLai={this.state.minHenLai} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({open: false})}>Đóng</Button>
          <Button onClick={(ev) => this.save()}>Lưu</Button>
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Ngày hẹn lại</TableCell>
              <TableCell>Lý do hẹn lại</TableCell>
              <TableCell>Đề xuất xử lý</TableCell>
              <TableCell>
                  <IconButton
                    disabled={this.props.items.length >= 6}
                    onClick={this.openAddDialog}>
                    <Add />
                  </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.items.map((item, i) => (
              <TableRow key={item.id}>
                <TableCell>{dayjs(item.ngayHenLai).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{!item.LyDo ? "" : item.LyDo.name}</TableCell>
                <TableCell>{item.deXuat}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={(ev) => this.openEditDialog(ev, item, i)}>
                    <Edit />
                  </IconButton>
                  <IconButton
                    disabled={i === 0}
                    onClick={(ev) => this.setState({openDeleteDialog: true, item})}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>);
  }
}
 
export default HenQuayLaiPage;