import React, { Component } from "react";
import Service from "../../services/lyDoService";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LyDoDialog from "./LyDoDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const service = new Service();

class LyDoPage extends Component {
  state = {
    items: [],
    openDialog: false,
    updateModel: { name: "" },
    openDeleteDialog: false
  };
  renderTable = () => {
    service.getList({}).then((rs) => {
      this.setState({ items: rs.items });
    });
    this.setState({ openDialog: false });
  };
  componentDidMount = () => {
    this.renderTable();
  };
  handleClickClose = (ev, rs) => {
    if (rs !== "backdropClick") {
      this.setState({ openDialog: false });
    }
  };
  openDialog = (ev) => {
    this.setState({updateModel:{name: ""}})
    this.setState({ openDialog: true });
  };

  // on click edit item
  clickEdit = (ev, item) => {
    ev.preventDefault();
    this.setState({ updateModel: item, openDialog: true });
  };

  openDeleteDialog(ev, item) {
    this.setState({updateModel: item, openDeleteDialog: true});
  }

  handleDeleteDialogClose = (ev, rs) => {
    this.setState({openDeleteDialog: false});
  }

  handleDelete = (ev, rs) => {
    const deleteId = this.state.updateModel.id;
    service.delete(deleteId).then((rs) => {
        this.renderTable();
    });
    this.setState({openDeleteDialog: false});
  }

  render() {
    return (
      <div>
        <Button onClick={this.openDialog}>Thêm lý do mới</Button>
        {/* Edit diaglog */}
        <LyDoDialog
          open={this.state.openDialog}
          onClose={this.handleClickClose}
          model={this.state.updateModel}
          service={service}
          onCreated={this.renderTable}
        />

        {/* delete diaglog */}
        <Dialog
          open={this.state.openDeleteDialog}
          keepMounted
          onClose={this.handleDeleteDialogClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Cảnh báo"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Bạn có chắc chắn muốn xóa
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteDialogClose}>Đóng</Button>
            <Button onClick={this.handleDelete}>Đồng ý</Button>
          </DialogActions>
        </Dialog>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nội dung</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    <IconButton onClick={(ev) => this.clickEdit(ev, item)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={(ev) => this.openDeleteDialog(ev, item)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default LyDoPage;
