//import logo from './logo.svg';
import './App.css';
import {useState} from 'react';
import MainPage from './component/main/mainPage';
import requestHelper from './utils/requestHelper';
import { Alert, Backdrop, CircularProgress, Snackbar } from '@mui/material';

import { green, pink } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

import { ThemeProvider } from "@mui/material";

export const appTheme = createTheme({
  palette: {
    primary: green,
    secondary: pink,
  },
});

function App() {
  const [loading, setLoading] = useState(false);
  const [onError, setOnError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  requestHelper.startRequest = () => {
    setLoading(true);
  }
  requestHelper.stopRequest = () => {
    setLoading(false);
  }
  requestHelper.onError = (errorMessage) => {
    setOnError(true);
    setErrorMessage(errorMessage);
  }
  return (
    <>
    <ThemeProvider theme={appTheme}>
    <Snackbar open={onError} autoHideDuration={3000} onClose={() => setOnError(false)}>
      <Alert onClose={() => setOnError(false)} severity="error" sx={{ width: '100%' }}>
        {errorMessage}
      </Alert>
    </Snackbar>
    <Backdrop
      sx={{ color: '#fff', zIndex: 10000 }}
      open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
    <MainPage></MainPage>
    </ThemeProvider>
    </>
  );
}

export default App;
