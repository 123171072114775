import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Validator from '../../utils/validate';

export default function CuaKhauDialog(props) {
    const [updateModel, setUpdateModel] = React.useState(props.model);
    const [errors, setErrors] = React.useState({});
    const updateTimer = React.useRef(null);
    
    const rules = [
        {
          field: 'name',
          method: 'isEmpty',
          validWhen: false,
          message: 'Vui lòng nhập.',
        },
        {
          field: 'name',
          method: 'isLength',
          args: [{min: 5}],
          validWhen: true,
          message: 'Vui lòng nhập ít nhất 3 kí tự',
        }
    ];

    React.useEffect(() => {
        if(!updateTimer.current) {
          setUpdateModel(props.model)
        }
    }, [props.model]);

    const validator = new Validator(rules);
    const save = () => {
        // validate
        const errors = validator.validate(updateModel);
        setErrors(errors);
        if (!validator.isValid) {
            return;
        }
        // create object
        if (updateModel.id) {
            props.service.update(updateModel).then((rs) => {
                setUpdateModel(rs);
                props.onCreated(rs);
            }).catch((ex) => {
                console.log(ex);
            });
        } else {
            props.service.create(updateModel).then((rs) => {
                setUpdateModel(rs);
                props.onCreated(rs);
            }).catch((ex) => {
                console.log(ex);
            });
        }
    }

    const handleInput = (e) => {
        let model = {...updateModel};
        model[e.target.name] = e.target.value;
        setUpdateModel(model);
    };

    return (
        <Dialog fullWidth={true} open={props.open} onClose={props.onClose}>
            <DialogTitle>{!updateModel.id ? "Thêm mới " : "Chỉnh sửa "} {updateModel.loai}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    name='name'
                    label="Tên"
                    type="email"
                    fullWidth
                    variant="standard"
                    value={updateModel.name}
                    onChange={handleInput}
                    error={errors.name}
                    helperText={errors.name}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Đóng</Button>
                <Button onClick={save}>{!updateModel.id ? "Thêm mới " : "Chỉnh sửa "}</Button>
            </DialogActions>
        </Dialog>
    );
}