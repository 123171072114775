import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Validator from "../../utils/validate";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import PhongBanService from "../../services/phongBanService";
import { MenuItem, Select, InputLabel } from "@mui/material";

export default function LichVePhepDialog(props) {
  const phongBanService = new PhongBanService();

  const [updateModel, setUpdateModel] = React.useState(props.model);
  const [errors, setErrors] = React.useState({});
  const [congTys, setCongTys] = React.useState([]);

  const updateTimer = React.useRef(null);
  const handleChangeNgayDi = (newValue) => {
    const nd = newValue.toDate();
    let model = { ...updateModel };
    model.ngayDi = nd;
    setUpdateModel(model);
  };
  const handleChangeNgayVe = (newValue) => {
    const nv = newValue.toDate();
    let model = { ...updateModel };
    model.ngayVe = nv;
    setUpdateModel(model);
  };

  const rules = [
    {
      field: "name",
      method: "isEmpty",
      validWhen: false,
      message: "Vui lòng nhập tên",
    },
    {
      field: "name",
      method: "isLength",
      args: [{ min: 5 }],
      validWhen: true,
      message: "Vui lòng nhập ít nhất 3 kí tự",
    },
    {
      field: "ngayDi",
      method: function (date) {
        if (date < new Date()) {
          return false;
        }
        return true;
      },
      validWhen: true,
      message: "Ngày đi phải lớn hơn ngày hiện tại",
    },
    {
      field: "ngayVe",
      method: function (date) {
        if (date < updateModel.ngayDi) {
          return false;
        }
        return true;
      },
      validWhen: true,
      message: "Ngày về phải lớn hơn ngày đi",
    },
  ];

  React.useEffect(() => {
    phongBanService.getDsKhulienhop().then((data) => {
      setCongTys(data.items);
    });

    if (!updateTimer.current) {
      if(!props.model.id && congTys.length > 0) {
        let congTyId = congTys[0].id;
        props.model.PhongBanId = congTyId;
      }
      setUpdateModel(props.model);
    }
  }, [props.model]);

  const validator = new Validator(rules);
  const save = () => {
    // validate
    const errors = validator.validate(updateModel);
    setErrors(errors);
    if (!validator.isValid) {
      return;
    }
    // create object
    if (updateModel.id) {
      props.service
        .update(updateModel)
        .then((rs) => {
          setUpdateModel(rs);
          props.onCreated(rs);
        })
        .catch((ex) => {
          console.log(ex);
        });
    } else {
      props.service
        .create(updateModel)
        .then((rs) => {
          setUpdateModel(rs);
          props.onCreated(rs);
        })
        .catch((ex) => {
          console.log(ex);
        });
    }
  };

  const handleInput = (e) => {
    let model = { ...updateModel };
    model[e.target.name] = e.target.value;
    setUpdateModel(model);
  };

  return (
    <Dialog fullWidth={true} open={props.open} onClose={props.onClose}>
      <DialogTitle>
        {!updateModel.id ? "Thêm mới " : "Chỉnh sửa "} {updateModel.loai}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          name="name"
          label="Tên"
          type="email"
          fullWidth
          variant="standard"
          value={updateModel.name}
          onChange={handleInput}
          error={errors.name}
          helperText={errors.name}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className={!errors.ngayDi ? "" : "custom-error-message"}>
            <DatePicker
              inputFormat="DD/MM/YYYY"
              renderInput={(props) => (
                <TextField
                  autoFocus
                  margin="dense"
                  name="ngayDi"
                  id="ngayDi"
                  fullWidth
                  {...props}
                />
              )}
              label="Ngày đi"
              value={dayjs(updateModel.ngayDi)}
              onChange={handleChangeNgayDi}
            />
            <div className="error-message">{errors.ngayDi}</div>
          </div>
          <div className={!errors.ngayVe ? "" : "custom-error-message"}>
            <DatePicker
              inputFormat="DD/MM/YYYY"
              renderInput={(props) => (
                <TextField
                  autoFocus
                  margin="dense"
                  name="ngayVe"
                  id="ngayVe"
                  fullWidth
                  {...props}
                />
              )}
              label="Ngày về"
              value={updateModel.ngayVe}
              onChange={handleChangeNgayVe}
            />
            <div className="error-message">{errors.ngayVe}</div>
          </div>
        </LocalizationProvider>

        <Stack spacing={1}>
          <InputLabel id="demo-simple-select-helper-label">Khu liên hợp</InputLabel>
          <Select
            value={updateModel.PhongBanId} // dua phong ban vao
            onChange={handleInput}
            displayEmpty
            name="PhongBanId"
            inputProps={{ "aria-label": "Without label" }}
          >
            {congTys.map((ct) => (
              <MenuItem key={ct.id} value={ct.id}>
                {ct.name}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Đóng</Button>
        <Button onClick={save}>
          {!updateModel.id ? "Thêm mới " : "Chỉnh sửa "}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
