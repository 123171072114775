import ABSService from "./absService";
import requestHelper from "../utils/requestHelper";

class LichVePhepService extends ABSService {
    constructor() {
        super("lichvephep");
    }

    getNew = async(NhanVienId) => {
        const data = await requestHelper.get("/api/lichvephep/new/" + NhanVienId, {});
        return data;
    }
    searchLVPhep = async(name, offset, limit) => {
        const url = `/api/lichvephep/layLVPhep`
        let data = await requestHelper.post(url, {name, offset, limit});
        return data;
    }
}

export default LichVePhepService;