import React, { Component } from 'react';
import Button from "@mui/material/Button";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Input, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Stack } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import NhanVienView from './NhanVienView';

const initNhanVien = {
    id: undefined,
    maNV: '',
    name: '',
    NgaySinh: new Date(),
    ngayVaoLam: new Date(),
    chucVu: '',
    CMND: '',
    hoChieu: '',
    ngayHetHan: new Date(),
    visaLaoDong: '',
    diaChiTamTru: '',
    diaChi: '',
    dienThoai: ''
}

class SyncDeepHRMPage extends Component {

    constructor(props) {
        super(props);
        this.service = props.service;
    }

    state = {
        items: [],
        nhanVien: {...initNhanVien},
        searchError: false,
        keyword: "",
    }

    seachDeep = () => {
        if (!this.state.keyword) {
            this.setState({searchError: true});
            return;
        }
        this.setState({visible: false});
        const masks = document.getElementById('mask1');
        masks.style.visibility = "visible";
        this.service.searchDeep(this.state.keyword).then((items) => {
            this.setState({items});
            masks.style.visibility = "hidden";
        }).catch((ex) => {
            masks.style.visibility = "hidden";
        });
    }

    changeSelected(ev, index, item) {
        Object.assign(this.state.nhanVien, item);
        this.setState({nhanVien: this.state.nhanVien, selected: index});
    }
    
    render() { 
        return (
            <Dialog fullWidth={true} maxWidth="lg" open={this.props.open} onClose={this.props.onClose}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={12}>
                            Tìm kiếm nhân sự
                        </Grid>
                        <Grid item xs={12}>
                            
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={3}>
                            <Stack direction='row'>
                                <Input
                                    error={this.state.searchError}
                                    onChange={(ev) => {this.setState({keyword : ev.target.value})}}
                                    sx={{width:"100%"}}
                                    placeholder="Tìm theo tên hay mã" />
                                <IconButton
                                    onClick={() => this.seachDeep()}>
                                    <SearchIcon />
                                </IconButton>
                            </Stack>
                            <List sx={{height: '55vh', overflowY: 'scroll'}}>
                            {!this.state.items ? 
                                ""
                                :
                                this.state.items.map((item, index, array) => {
                                return(
                                <ListItem key={item.id} disablePadding>
                                    <ListItemButton
                                        selected={this.state.selected === index}
                                        onClick={(ev) => this.changeSelected(ev, index, item)}>
                                        <ListItemText primary={`${item.maNV} - ${item.name}`} />
                                    </ListItemButton>
                                </ListItem>
                                );
                            })}
                            </List>
                        </Grid>
                        <Grid item xs={9} paddingLeft="0.5rem">
                            <NhanVienView
                                isInsert={true}
                                service={this.service}
                                nhanVien={this.state.nhanVien}
                                onInsertCompleted={this.props.onInsertCompleted} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{width: "100%"}}>
                    <Stack sx={{width: "100%"}}>
                        <Stack spacing={2} direction='row' justifyContent='end'>
                            <Button variant='contained' onClick={this.props.onClose}>Đóng</Button>
                        </Stack>
                    </Stack>
                </DialogActions>
          </Dialog>
        );
    }
}
 
export default SyncDeepHRMPage;
