import ABSService from "./absService";
import requestHelper from "../utils/requestHelper";

class QLLaoDongService extends ABSService {
    constructor() {
        super("qllaodong");
    }

    search = async(name, page = 1, count = 15) => {
        return await this.getList({name:`${name}`, page, count});
    }

    setRolse = async(data) => {
        const rs = await requestHelper.post('/api/setroles', data);
        return rs;
    }

    getCurrentUser = async(data) => {
        const rs = await requestHelper.post('/api/u/current', data);
        return rs;
    }
}

export default QLLaoDongService;