const CONS = {
    LoaiVePheps: ['Về phép định kỳ', 'Về tết'],
    TrangThais: ['Đã quay lại','Dự kiến nghỉ việc', 'Nghỉ việc', 'Gia hạn lại'],
    DeXuatHenQuayLai: ['Tiếp tục ký HĐLĐ', 'Đề xuất cho nghỉ việc'],
    LoaiPhongBans: ['Khu liên hợp','Công ty','Phòng ban'],
    LOGIN_URL: 'https://workplace.thacoagri.com.vn',
    PUBLIC_URL: '/ql-vephep',
    SERVER_DOMAIN: 'https://vephep.thacoagri.com.vn',
}

export const LoaiVePheps = CONS.LoaiVePheps;
export const TrangThais = CONS.TrangThais;
export const DeXuatHenQuayLai = CONS.DeXuatHenQuayLai;
export const LoaiPhongBans = CONS.LoaiPhongBans;
export const LOGIN_URL = CONS.LOGIN_URL;
export const SERVER_DOMAIN = CONS.SERVER_DOMAIN;
export const PUBLIC_URL = CONS.PUBLIC_URL;
export const DEBUG_MODE = true;