import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import { Checkbox, Grid } from '@mui/material';

const PhongBanItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));


function PhongBanItem(props) {
  const {
    bgColor,
    color,
    label,
    onAddClick,
    onEditClick,
    onDeleteClick,
    item,
    hasCheckBox,
    onItemSelected,
    onChangeExpand,
    checked,
    ...other
  } = props;

  const onChangeSelectedItem = (ev, item) => {
    ev.stopPropagation();
    let selected = ev.target.checked;
    props.onItemSelected(item, selected);
  }

  return (
    <PhongBanItemRoot
      label={
        <Grid container>
          {
              props.hasCheckBox ? 
              (
                <Grid item xs={1}>
                  <Checkbox checked={props.checked} onClick={(ev) => onChangeSelectedItem(ev, item)}></Checkbox>
                </Grid>
              ) 
                : ""
          }
          <Grid item xs={props.hasCheckBox ? 11 : 9}>
            <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1, padding:"0.6rem 1rem" }}>
              {item.name}
            </Typography>
          </Grid>
          {
              props.hasCheckBox ? 
              (
                ""
              ) 
                :
              (
                <>
                <Grid item xs={1}>
                  <IconButton disabled={item.loai == 'Phòng ban'} onClick={(ev) => props.onAddClick(ev, props.item)}>
                    <AddCircleIcon  />
                  </IconButton>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={(ev) => props.onEditClick(ev, props.item)}>
                    <EditIcon />
                  </IconButton>
                </Grid>
                </>
              )
          }
          
        </Grid>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  );
}

PhongBanItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  onAddClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  hasCheckBox: PropTypes.bool,
  onItemSelected: PropTypes.func,
  checked: PropTypes.bool,
};
 
export default PhongBanItem;