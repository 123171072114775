import React, { Component } from 'react';
import { Grid, Stack, Button, TextField, Autocomplete, Breadcrumbs, Link } from '@mui/material';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Validator from '../../utils/validate';
import PhongBanService from '../../services/phongBanService';

import rules from './NhanVienValidate';

class NhanVienView extends Component {
    constructor(props) {
        super(props);
        this.validator = new Validator(rules);
        this.service = this.props.service;
        this.phongBanService = new PhongBanService();
    }

    state = {
        nhanVien: {
            id: undefined,
            maNV: '',
            name: '',
            NgaySinh: new Date(),
            ngayVaoLam: new Date(),
            chucVu: '',
            hoChieu: null,
            ngayHetHan: new Date(),
            visaLaoDong: null,
            diaChi: '',
            dienThoai: ''
        },
        errors: {},
        mode: 0,
        phongbans: [],
        phongBanTree: []
    }

    componentDidMount = () => {
        this.phongBanService.getDsPhongBan().then((rs) => {
            this.setState({ phongbans: rs.items });
        });
    }
    

    bindNhanVienToView(nv) {
        const nhanVien = Object.assign({}, nv);
        for (let key in nhanVien) {
            if (key.toLowerCase().includes('ngay')) {
                nhanVien[key] = dayjs(nhanVien[key]).toDate();
            }
        }
        this.setState({ nhanVien });
    }

    shouldComponentUpdate = (nextProps, preState) => {
        if (nextProps.nhanVien.maNV !== this.state.nhanVien.maNV) {
            this.bindNhanVienToView(nextProps.nhanVien);
            let mode = 0;
            if (this.props.isInsert) mode = 2;
            this.setState({ mode });
            if (nextProps.nhanVien.id) {
                this.phongBanService.getPhongBanTreeForNV(nextProps.nhanVien.id).then((data) => {
                    if (data) {
                        this.setState({ phongBanTree: data });
                    }
                });
            }
            return false;
        }
        return true;
    }

    renderInput = (inputName) => {
        const type = inputName.toLowerCase().includes('ngay') ? 'date' : 'string';
        let value = this.state.nhanVien[inputName];
        if (Date.parse(value)) {
            value = new Date(Date.parse(value));
        }
        if (type === 'string') {
            return (
                <TextField
                    autoFocus={inputName === "maNV"}
                    disabled={this.state.mode === 0}
                    margin="dense"
                    id={inputName}
                    name={inputName}
                    type="text"
                    fullWidth
                    variant="standard"
                    value={this.state.nhanVien[inputName]}
                    onChange={this.handleChange}
                    error={this.state.errors[inputName]}
                    helperText={this.state.errors[inputName]}
                />
            );
        }
        if (value instanceof Date) {
            return (
                <div className={!this.state.errors[inputName] ? "" : "custom-error-message"}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            disabled={this.state.mode === 0}
                            inputFormat="DD/MM/YYYY"
                            name={inputName}
                            value={dayjs(this.state.nhanVien[inputName])}
                            onChange={(ev) => this.handleChange(ev, inputName)}
                            renderInput={(params) => <TextField name={inputName} {...params} />}
                        />
                    </LocalizationProvider>
                    <div className='error-message'>{this.state.errors[inputName]}</div>
                </div>
            );
        }
    }

    handleChange = (ev, inputName = null) => {
        let value = null;
        if (!inputName) {
            inputName = ev.target.name;
            value = ev.target.value;
        } else {
            value = ev.toDate();
        }
        let nhanVien = Object.assign({}, this.state.nhanVien);
        nhanVien[inputName] = value;
        this.setState({ nhanVien });
    }

    changePhongBan = (ev, pb) => {
        let nhanVien = Object.assign({}, this.state.nhanVien);
        nhanVien.PhongBanId = pb.id;
        this.setState({ nhanVien });
    }
    changeKhuLienHop = (ev, pb) => {
        let nhanVien = Object.assign({}, this.state.nhanVien);
        nhanVien.PhongBanId = pb.id;
        this.setState({ nhanVien });
    }

    changeToEdit = () => {
        if (this.state.mode !== 1) {
            this.setState({ mode: 1 });
        } else {
            this.setState({ mode: 0 });
        }
    }

    edit = () => {
        const errors = this.validator.validate(this.state.nhanVien);
        this.setState({ errors });
        if (!this.validator.isValid) {
            return;
        }
        if (this.state.mode === 1) {
            this.service.update(this.state.nhanVien).then((nv) => {
                this.bindNhanVienToView(nv);
                this.props.onEditComplete();
            });
        } else {
            this.service.create(this.state.nhanVien).then((nv) => {
                this.bindNhanVienToView(nv);
                this.props.onInsertCompleted(nv);
            });
        }
    }

    findPhongBan = () => {
        if (!this.state.nhanVien.PhongBanId) return null;
        const phongBans = this.state.phongbans.filter((item) => {
            return item.id === this.state.nhanVien.PhongBanId;
        });
        if (phongBans.length > 0) {
            return phongBans[0];
        }
        return null;
    }

    render() {
        return (
            <>
                {!this.state.nhanVien.maNV ?
                    <h4 style={{ textAlign: 'center' }}>Không có dữ liệu</h4>
                    :
                    <Grid container>
                        {this.state.mode !== 2 ?
                            <Grid item xs={12} visibility={this.state.mode !== 2}>
                                <Button
                                    onClick={this.changeToEdit}
                                    size="medium"
                                    variant="contained"
                                    sx={{ margin: "0.1rem", float: 'right' }} >
                                    {this.state.mode === 0 ? "Chỉnh sửa" : "Thoát"}
                                </Button>
                            </Grid>
                            :
                            ""
                        }

                        <Grid item xs={3}>Mã số nhân viên</Grid>
                        <Grid item xs={9}>
                            {this.renderInput("maNV")}
                        </Grid>

                        <Grid item xs={3}>Tên</Grid>
                        <Grid item xs={9}>
                            {this.renderInput("name")}
                        </Grid>

                        <Grid item xs={3}>Ngày sinh</Grid>
                        <Grid item xs={9}>
                            {this.renderInput("ngaySinh")}
                        </Grid>

                        <Grid item xs={3}>Ngày vào làm</Grid>
                        <Grid item xs={9}>
                            {this.renderInput("ngayVaoLam")}
                        </Grid>

                        <Grid item xs={3}>Chức vụ</Grid>
                        <Grid item xs={9}>
                            {this.renderInput("chucVu")}
                        </Grid>

                        <Grid item xs={3} paddingY={1}>Chuỗi phòng ban</Grid>
                        <Grid item xs={9} paddingY={1}>
                            <Breadcrumbs aria-label="breadcrumb">
                                {
                                    this.state.phongBanTree.map((item) => (
                                        <Link underline="hover" color="inherit">
                                           {item.name}
                                        </Link>
                                    ))
                                }
                            </Breadcrumbs>
                        </Grid>

                        <Grid item xs={3}>Phòng ban</Grid>
                        <Grid item xs={9}>
                            <Stack spacing={1}>
                                <Autocomplete
                                    disabled={this.state.mode === 0}
                                    id="phongBan"
                                    options={this.state.phongbans}
                                    groupBy={(option) => option.parent.name}
                                    value={this.findPhongBan()}
                                    getOptionLabel={(option) => `${option.id} - ${option.name} - ${option.parent.name}`}
                                    onChange={this.changePhongBan}
                                    renderInput={(item) => (
                                        <TextField
                                            error={this.state.errors.PhongBanId}
                                            helperText={this.state.errors.PhongBanId}
                                            autoFocus
                                            margin="dense"
                                            {...item}
                                            fullWidth
                                            variant="standard"
                                        />
                                    )}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={3}>
                            CMND/CCCD
                        </Grid>
                        <Grid item xs={9}>
                            {this.renderInput("CMND")}
                        </Grid>
                        <Grid item xs={3}>
                            Hộ chiếu
                        </Grid>
                        <Grid item xs={9}>
                            {this.renderInput("hoChieu")}
                        </Grid>

                        <Grid item xs={3}>
                            Ngày hết hạn hộ chiếu
                        </Grid>
                        <Grid item xs={9}>
                            {this.renderInput("ngayHetHan")}
                        </Grid>

                        <Grid item xs={3}>
                            Visa lao động
                        </Grid>
                        <Grid item xs={9}>
                            {this.renderInput("visaLaoDong")}
                        </Grid>

                        <Grid item xs={3}>
                            Điện thoại
                        </Grid>
                        <Grid item xs={9}>
                            {this.renderInput("dienThoai")}
                        </Grid>
                        
                        <Grid item xs={3}>
                            Địa chỉ tạm trú
                        </Grid>
                        <Grid item xs={9} >
                            {this.renderInput("diaChiTamTru")}
                        </Grid>
                        <Grid item xs={3}>
                            Địa chỉ
                        </Grid>
                        <Grid item xs={9} >
                        {this.renderInput("diaChi")}
                        </Grid>

                        {this.state.mode !== 2 ?
                            <>
                                <Grid item xs={3}>
                                    Trạng thái
                                </Grid>
                                <Grid item xs={9}>
                                    {this.props.nhanVien.trangThai}
                                </Grid>
                            </>
                            :
                            ""
                        }

                        <Grid item xs={12}>
                            <Button onClick={this.edit}
                                sx={{ display: this.state.mode === 0 ? "none" : "block", margin: "0.1rem", float: 'right' }}
                                size="medium" variant="contained">
                                Lưu
                            </Button>
                        </Grid>
                    </Grid>}
            </>);
    }
}

export default NhanVienView;