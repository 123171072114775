import React, { Component } from 'react';
import { Button, Grid, TextField, Box, Tab, GlobalStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import VePhepService from '../../services/vePhepService';
import dayjs from 'dayjs';
import PhongBanService from '../../services/phongBanService';
import Validator from '../../utils/validate';

import rules from '../nhanvien/NhanVienValidate';
import {LoaiPhongBans} from '../../utils/cons';

const columns = [
    { field: 'maNV', headerName: 'MNV', width: 100, type: 'text' },
    { field: 'name', headerName: 'Tên', width: 130, type: 'text' },
    { field: 'chucVu', headerName: 'Chức vụ', width: 100, type: 'text' },
    { field: 'ngaySinh', headerName: 'Ngày Sinh', width: 130, type: 'date', valueFormatter: params => params?.value ? dayjs(params?.value).format("DD/MM/YYYY") : "" },
    { field: 'ngayVaoLam', headerName: 'Ngày vào làm', width: 100, type: 'date', valueFormatter: params => params?.value ? dayjs(params?.value).format("DD/MM/YYYY") : "" },
    { field: 'maPB', headerName: 'Mã phòng ban HRM', width: 100, type: 'text' },
    { field: 'hoChieu', headerName: 'Hộ chiếu', width: 130, type: 'text' },
    { field: 'ngayHetHan', headerName: 'Ngày hết hạn', width: 100, type: 'date', valueFormatter: params => params?.value ? dayjs(params?.value).format("DD/MM/YYYY") : "" },
    { field: 'visaLaoDong', headerName: 'Visa Lao Động', width: 130, type: 'text' },
    { field: 'diaChi', headerName: 'Địa chỉ', width: 100, type: 'text' },
    { field: 'dienThoai', headerName: 'Điện thoại', width: 130, type: 'text' },
    { field: 'CMND', headerName: 'CMND', width: 100, type: 'text' },
    { field: 'diaChiTamTru', headerName: 'diaChiTamTru', width: 130, type: 'text' },
];

class ImportFile extends Component {
    constructor(props) {
        super(props);
        this.vePhepService = new VePhepService();
        this.validator = new Validator(rules);
        this.phongBanService = new PhongBanService();
    }

    componentDidMount = () => {
        this.phongBanService.getDsPhongBan().then((rs) => {
            this.setState({ phongbans: rs.items });
        });
    }

    state = { validData : [], inValidData: [], tabIndex: "1", openDialog: false }
    
    processingFile = (e) => {
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
            this.phongBanService.getDsPhongBan().then((rs) => {
                const phongbans = rs.items;
                var data = e.target.result;
                let readedData = XLSX.read(data, {type: 'binary'});
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];

                data = XLSX.utils.sheet_to_json(ws, {raw: false});
                let validData = [];
                let inValidData = [];
                let id = 0;
                let tabIndex = "1";
                for (let row of data) {
                    let rowData = {id};
                    for (let column of columns) {
                        let value = !row[column.headerName] ? "" : row[column.headerName];
                        if (column.type == 'text') {
                            rowData[column.field] = value.toString();
                            continue;
                        }
                        if (column.type == 'date') {
                            rowData[column.field] = dayjs(row[column.headerName], {format: 'D/M/YY'}).toDate();
                            continue;
                        }
                        rowData[column.field] = row[column.headerName];
                    }
                    let maPB = rowData.maPB.split('-')[0].trim();
                    let phongban = phongbans.filter(q => maPB.startsWith(q.maHRM) && q.loai === LoaiPhongBans[2]);
                    rowData.PhongBanId = phongban.length > 0 ? phongban[phongban.length - 1].id : null;
                    rowData.errors = this.validator.validate(rowData);
                    if (rowData.errors && Object.keys(rowData.errors).length > 0) {
                        inValidData.push(rowData);
                        tabIndex = "2";
                    } else {
                        validData.push(rowData);
                    }
                    id++;
                }
                this.setState({validData, inValidData, tabIndex});
            });
        };
        reader.readAsBinaryString(f);
    }

    importBath = (ev) => {
        this.vePhepService.importBath(this.state.validData)
        .then((rs) => {
            this.setState({alertMessage: `Đã thêm thành công ${rs.count} nhân sự`, openDialog: true})
        })
        .catch((ex) => {
            this.setState({alertMessage: ex.message, openDialog: true})
        });
    }

    downloadErrorValue = (ev) => {
        let workBook = XLSX.utils.book_new();
        let sheet = XLSX.utils.json_to_sheet(this.state.inValidData);
        XLSX.utils.book_append_sheet(workBook, sheet, "Dữ liệu lỗi");
        const buffer = XLSX.write(workBook, { type: 'buffer', bookType: 'xls' });
        const blob = new Blob([buffer]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = "loi" + dayjs(new Date()).format("_DD_MM_YYYY") + ".xls";
        a.click();
        window.URL.revokeObjectURL(url);
    }

    render() { 
        return (
            <>
            <Dialog
                open={this.state.openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">
                {"Thông báo"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {this.state.alertMessage}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(ev) => this.setState({openDialog: false})} autoFocus>
                        Đóng
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container>
                <GlobalStyles styles={{ '.error-cell': { backgroundColor: 'red' } }}></GlobalStyles>
                <Grid item xs={9}>
                    <TextField type="text" variant="outlined" size="small" style={{width: "100%"}}></TextField>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        variant="contained"
                        component="label"
                    >
                        Upload File
                        <input type="file" onChange={this.processingFile} style={{width: "0px"}} />
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <TabContext value={this.state.tabIndex}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={(ev, tabIndex) => {this.setState({tabIndex})}} aria-label="Thông tin nhân viên">
                                <Tab label="Dữ liệu đúng" value="1" />
                                <Tab label="Dữ liệu sai" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1" sx={{padding: "0px !important"}}>
                            <Box sx={{ height: "60vh", width: '100%' }}>
                                <DataGrid
                                    rows={this.state.validData}
                                    columns={columns}
                                    pageSize={30}
                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    experimentalFeatures={{ newEditingApi: true }}
                                />
                            </Box>
                            <Button variant='contained' onClick={this.importBath} disabled={this.state.validData.length == 0} sx={{float: 'right', marginTop: '1rem'}}>Import</Button>
                        </TabPanel>
                        <TabPanel value="2" sx={{padding: "0px !important"}}>
                            <Box sx={{ height: "60vh", width: '100%' }}>
                                <DataGrid
                                    rows={this.state.inValidData}
                                    columns={columns}
                                    pageSize={30}
                                    rowsPerPageOptions={[5]}
                                    disableSelectionOnClick
                                    experimentalFeatures={{ newEditingApi: true }}
                                    getCellClassName={(params) => {
                                        let errorNames = Object.keys(params.row.errors);
                                        if (errorNames.includes(params.field)) {
                                          return 'error-cell';
                                        } else {
                                            return '';
                                        }
                                    }}
                                />
                            </Box>
                            <Button variant='contained' onClick={this.downloadErrorValue} disabled={this.state.inValidData.length == 0} sx={{float: 'right', marginTop: '1rem'}}>Tải dữ liệu sai</Button>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
            </>
        );
    }
}
 
export default ImportFile;