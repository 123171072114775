import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Validator from '../../utils/validate';
import PhongBanTree from '../phongban/PhongBanTree';
import PhongBanService from '../../services/phongBanService';
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
const phongBanService = new PhongBanService();

export default function QLLaoDongDialog(props) {
    const [errors, setErrors] = React.useState({});
    const updateTimer = React.useRef(null);
    const [tree, setTree] = React.useState([]);
    const [openPhanQuyen, setOpenPhanQuyen] = React.useState(false);
    const [updateModel, setUpdateModel] = React.useState(props.model);
    const [isEditMode, setIsEditMode] = React.useState(false);
    
    const rules = [
        {
          field: 'name',
          method: 'isEmpty',
          validWhen: false,
          message: 'Vui lòng nhập.',
        },
        {
          field: 'name',
          method: 'isLength',
          args: [{min: 3}],
          validWhen: true,
          message: 'Vui lòng nhập ít nhất 3 kí tự',
        }
    ];

    React.useEffect(() => {
        if(!updateTimer.current) {
            setUpdateModel(props.model);
            phongBanService.getTree().then(rs => {
                setTree(rs);
            });
            setIsEditMode(!props.model.name);
        }
    }, [props.model]);

    const validator = new Validator(rules);
    const save = () => {
        // validate
        const errors = validator.validate(updateModel);
        setErrors(errors);
        if (!validator.isValid) {
            return;
        }
        // create object
        if (updateModel.id) {
            props.service.update(updateModel).then((rs) => {
                setUpdateModel(rs);
                props.onCreated(rs);
            }).catch((ex) => {
                console.log(ex);
            });
        } else {
            props.service.create(updateModel).then((rs) => {
                setUpdateModel(rs);
                props.onCreated(rs);
            }).catch((ex) => {
                console.log(ex);
            });
        }
    }

    const handleInput = (value, name) => {
        let model = {...updateModel};
        model[name] = value;
        setUpdateModel(model);
    };

    return !updateModel ? (<></>) : (
        <>
        <Dialog open={openPhanQuyen} fullWidth={true} onClose={() => {setOpenPhanQuyen(false)}}>
            <DialogTitle>Phân quyền phòng ban</DialogTitle>
            <DialogContent>
                <PhongBanTree
                    hasCheckBox={true}
                    tree={tree}
                    height="30rem"
                    width="100%"
                    selectedPhongBans={updateModel.permission}
                    onChangeSelected={(p) => handleInput(p, "permission")}
                    service={phongBanService} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {setOpenPhanQuyen(false)}}>Ok</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth={true} open={props.open} onClose={props.onClose}>
            <DialogTitle>{!updateModel.id ? "Thêm mới " : "Chỉnh sửa "} {updateModel.loai}</DialogTitle>
            <DialogContent>
                <TextField
                    disabled={!isEditMode}
                    autoFocus
                    margin="dense"
                    id="name"
                    name='name'
                    label="Tên đăng nhập"
                    type="email"
                    fullWidth
                    variant="standard"
                    value={updateModel.name}
                    onChange={(ev) => handleInput(ev.target.value, ev.target.name)}
                    error={errors.name}
                    helperText={errors.name}
                />
                <Grid container>
                    <Grid item xs={9}>
                        <TextField
                            disabled={true}
                            margin="dense"
                            label="Ds phòng ban phân quyền"
                            fullWidth
                            variant="standard"
                            value={updateModel.permission}
                        />
                    </Grid>
                    <Grid item xs={3} sx={{paddingTop:"1rem"}} justifyContent="right">
                        <Button
                            variant='contained'
                            sx={{float: 'right'}}
                            onClick={() => {setOpenPhanQuyen(true)}}>
                            Phân quyền
                        </Button>
                    </Grid>
                </Grid>
                <FormGroup>
                    <FormControlLabel control={<Checkbox defaultChecked={updateModel.isActive} name="isActive" onChange={(ev) => handleInput(ev.target.value, "isActive")} />} label="Kích hoạt" />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Đóng</Button>
                <Button onClick={save}>{!updateModel.id ? "Thêm mới " : "Chỉnh sửa "}</Button>
            </DialogActions>
        </Dialog>
        </>
    );
}