import React, { Component } from "react";
import Service from "../../services/lichVePhepService";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LichVePhepDialog from "./LichVePhepDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from 'dayjs';
import { Grid,Pagination, TextField } from "@mui/material";
import { Stack } from "@mui/system";

const service = new Service();
const MAX_PAGE_SIZE = 10;

class LichVePhepPage extends Component {
  state = {
    items: [],
    openDialog: false,
    updateModel: { name: "",isActive: true },
    openDeleteDialog: false,
    permission:[],
    keyword: "",
    pageCount: 0,
    offset: 1,
  };
  componentDidMount = () => {
    this.search();
  };
  handleClickClose = (ev, rs) => {
    if (rs !== "backdropClick") {
      this.setState({ openDialog: false });
    }
  };  
  renderTable = () => {
    this.loadList();
    this.setState({ openDialog: false });
  };
  openDialog = (ev) => {
    this.setState({updateModel: {name: "", permission:[], isActive: true}});
    this.setState({ openDialog: true });
  };
  search = () => {
    this.loadList(1);
  }
  
  loadList(page = 1) {
    service.getList({name : `%${this.state.keyword}%`, page, count: MAX_PAGE_SIZE}).then((rs) => {
      let pageCount = parseInt((rs.count / MAX_PAGE_SIZE)) + (rs.count % MAX_PAGE_SIZE == 0 ? 0 : 1);
      this.setState({items: rs.items, pageCount})
    });
    this.setState({ openDialog: false, offset: page }); 
  }
  // on click edit item
  clickEdit = (ev, item) => {
    ev.preventDefault();
    this.setState({ updateModel: item, openDialog: true });
  };
  openDeleteDialog(ev, item) {
    this.setState({updateModel: item, openDeleteDialog: true});
  }

  handleDeleteDialogClose = (ev, rs) => {
    this.setState({ openDeleteDialog: false });
  };
  handleDelete = (ev, rs) => {
    const deleteId = this.state.updateModel.id;
    service.delete(deleteId).then((rs) => {
        this.loadList(this.state.offset);
    });
    this.setState({openDeleteDialog: false});
  }
  ongChangePageCount = (ev, page) => {
    this.loadList(page);
  }

  render() {
    return (
      <div>
        <Button onClick={this.openDialog}>Thêm lịch về phép</Button>
        {/* Edit diaglog */}
        <LichVePhepDialog
          open={this.state.openDialog}
          onClose={this.handleClickClose}
          model={this.state.updateModel}
          service={service}
          onCreated={this.renderTable}
          dsCongty={this.state.dsCongty}
        />
        <Stack spacing={2}>
        <Grid margin={1} container spacing={1} >
            <Grid item xs={9}>
              <TextField id="outlined-basic"
                fullWidth
                onChange={(ev) => {this.setState({keyword: ev.target.value})}}
                size="small" variant="outlined" sx={{float: "right"}} />
            </Grid>
            <Grid item xs={2.5}>
              <Button fullWidth variant="contained" onClick={this.search} sx={{float: "right"}}>Tìm kiếm</Button>
            </Grid>
          </Grid>
           
        {/* delete diaglog */}
        <Dialog
          open={this.state.openDeleteDialog}
          keepMounted
          onClose={this.handleDeleteDialogClose}
          aria-describedby="alert-dialog-slide-description">

          <DialogTitle>{"Cảnh báo"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Bạn có chắc chắn muốn xóa
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteDialogClose}>Đóng</Button>
            <Button onClick={this.handleDelete}>Đồng ý</Button>
          </DialogActions>
        </Dialog>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Tên</TableCell>
                <TableCell>Từ ngày</TableCell>
                <TableCell>Đến ngày</TableCell>
                <TableCell>
                  Khu liên hợp
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{dayjs(item.ngayDi).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{dayjs(item.ngayVe).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{!item.PhongBan ? "" : item.PhongBan.name}</TableCell>
                  <TableCell>
                    <IconButton onClick={(ev) => this.clickEdit(ev, item)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={(ev) => this.openDeleteDialog(ev, item)}
                    >
                      <DeleteIcon/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination page={this.state.offset} onChange={this.ongChangePageCount} sx={{display: "flex", justifyContent: "center"}} count={this.state.pageCount} variant="outlined" />
        </Stack>  
      </div>
    );
  }
}

export default LichVePhepPage;
