import { Delete, Edit, Menu, RssFeed } from '@mui/icons-material';
import { TableCell, Button, TableContainer, Paper, Table, TableHead, TableRow, TableBody, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Stack } from '@mui/system';
import React, { Component } from 'react';
import VePhepService from '../../services/vePhepService';
import VePhepDialog from './VePhepDialog';
import dayjs from 'dayjs';

import CuaKhauService from "../../services/cuaKhauService";
import NhanVienService from '../../services/nhanvienService';

import {LoaiVePheps, TrangThais} from "../../utils/cons";
import LydoService from "../../services/lyDoService"

class VePhepPage extends Component {
  constructor(props) {
    super(props);
    this.service = new VePhepService();
    this.cuaKhauService = new CuaKhauService();
    this.lyDoService = new LydoService();
    this.NhanVienService = new NhanVienService();
  }

  state = {
    items: [],
    item: {},
    open: false,
    openDeleteDialog: false,
    cuaKhaus: [],
    loaiVePheps: LoaiVePheps,
    minDate: new Date(),
    lyDoHenLais: [],
    trangThai: null,
    enableAdd: false,
    toDate: new Date(),
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.NhanVienId !== this.props.NhanVienId) {
      this.reload(nextProps.NhanVienId);
      return true;
    }
    return true;
  }

  reload = (NhanVienId = null, open = false) => {
    if (!NhanVienId) NhanVienId = this.props.NhanVienId;
    this.service.getList({NhanVienId, order: "D_ngayDi"}).then(rs => {
      let minDate = rs.items.length > 0 ? (rs.items[0].ngayQuayLai ? rs.items[0].ngayQuayLai : rs.items[0].ngayHetPhep) : new Date();
      minDate = dayjs(minDate).toDate();
      minDate.setDate(minDate.getDate() + 1);
      for (let item of rs.items) {
        if (item.HenQuayLais && item.HenQuayLais.length > 0) {
          item.ngayHenLaiView = item.HenQuayLais[item.HenQuayLais.length - 1].ngayHenLai;
        }
      }
      this.setState({items: rs.items, open, minDate});
      this.cuaKhauService.getList().then((rs) => {
        this.setState({cuaKhaus: rs.items});
      });
      this.setState({enableAdd: rs.items.length === 0 || rs.items[0].trangThai === TrangThais[0]});
    });
  }

  onClose = () => {
    this.setState({open: false});
  }

  onOpenAdd = () => {
    let loai = LoaiVePheps[0];

    let ngayDi = dayjs(this.state.minDate);
    let ngayHetPhep = dayjs(this.state.minDate);
    let ngayVeThucTe = dayjs(this.state.minDate);

    const item = {
      loai,
      ngayDi,
      ngayVeThucTe,
      ngayHetPhep,
      NhanVienId: this.props.NhanVienId,
      CuaKhau: this.state.cuaKhaus[0],
      CuaKhauId: this.state.cuaKhaus[0].id,
      trangThai: null
    }
    this.setState({item,open: true});
  }
    // on click edit item
  openEditDialog = (ev, item) => {
    ev.preventDefault();
    this.setState({loaiVePheps: LoaiVePheps});
    this.setPropById(item, "CuaKhau", this.state.cuaKhaus);
    item.ngayHenLai = item.ngayHetPhep;
    item.lyDoHenLaiId = this.state.lyDoHenLais[0] ? this.state.lyDoHenLais[0].id : 0;
    this.setState({ item, open: true });
  };

  setPropById = (model, name, list) => {
    const id = model[name + "Id"];
    const data = list.filter(q => q.id === id)[0];
    model[name] = data;
  }
  
  componentDidMount = () => {
    this.reload();
    this.lyDoService.getList().then((rs) => {
      this.setState({lyDoHenLais: rs.items});
    });
  }

  handleDelete = (ev, rs) => {
    const deleteId = this.state.item.id;
    this.service.delete(deleteId).then((rs) => {
      this.reload();
    });
    this.setState({ openDeleteDialog: false });
  };

  handleDeleteDialogClose = (ev, rs) => {
    this.setState({ openDeleteDialog: false });
  };

  getTrangThai = (item) => {
    let now = new Date();
    if (!item.trangThai) {
      let ngayHetPhep = dayjs(item.ngayHetPhep).toDate();
      ngayHetPhep.setHours(23, 59, 0, 0);
      if (ngayHetPhep <= now) {
        return "Hết phép chưa quay lại làm việc";
      } else {
        return "Đang nghỉ phép";
      }
    }
    return item.trangThai;
  }

  render() { 
    return (
    <Stack>
        <Stack sx={{justifyContent: "right"}}>
          <Button disabled={!this.state.enableAdd} style={{float: "right"}} onClick={this.onOpenAdd}>Thêm về phép</Button>
        </Stack>
        <VePhepDialog
          items={this.state.items}
          service={this.service}
          onChange={() => this.reload(null, false)}
          open={this.state.open}
          onClose={this.onClose}
          model={this.state.item}
          minDate={this.state.minDate}
          loaiVePheps={this.state.loaiVePheps}
          lyDoHenLais = {this.state.lyDoHenLais}
          cuaKhaus={this.state.cuaKhaus} />
        
        <Dialog
          open={this.state.openDeleteDialog}
          keepMounted
          onClose={this.handleDeleteDialogClose}
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle>{"Cảnh báo"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Bạn có chắc chắn muốn xóa
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteDialogClose}>Đóng</Button>
            <Button onClick={this.handleDelete}>Đồng ý</Button>
          </DialogActions>
        </Dialog>
        
        <TableContainer component={Paper}>
          <Table className='small-table' sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Ngày đi</TableCell>
                <TableCell>Ngày hết phép</TableCell>
                <TableCell>Trạng thái</TableCell>
                <TableCell>Cửa khẩu</TableCell>
                <TableCell>Ngày quay lại</TableCell>
                <TableCell>Ngày nghỉ việc</TableCell>
                <TableCell>Ngày hẹn lại</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{dayjs(item.ngayDi).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{dayjs(item.ngayHetPhep).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>
                  {this.getTrangThai(item)}
                  </TableCell>
                  <TableCell>
                    {item.CuaKhau.name}
                  </TableCell>
                  <TableCell>{
                      item.trangThai === TrangThais[0]
                      ?
                      dayjs(item.ngayQuayLai).format('DD/MM/YYYY')
                      :
                      ""
                    }
                  </TableCell>
                  <TableCell>{
                      item.trangThai === TrangThais[1] || item.trangThai === TrangThais[2]
                      ?
                      dayjs(item.ngayNghiViec).format('DD/MM/YYYY')
                      :
                      ""
                    }
                  </TableCell>
                  <TableCell>{
                      item.trangThai === TrangThais[3]
                      ?
                      dayjs(item.ngayHenLaiView).format('DD/MM/YYYY')
                      :
                      ""
                    }
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(ev) => this.openEditDialog(ev, item)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={(ev) => this.setState({openDeleteDialog: true, item})}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    </Stack>);
  }
}
 
export default VePhepPage;