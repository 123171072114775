import { DEBUG_MODE } from "../../utils/cons";

import dayjs from 'dayjs';
import {TrangThais} from '../../utils/cons';

const isEditTrangThai = function(model) {
  if (model.isEditNgay) {
    return false;
  }

  if (model.id && (new Date()) > dayjs(model.ngayHetPhep).toDate()) {
    return true;
  }
  return false;
}

const rules = [
    // Ngày đi
    {
      field: 'ngayDi',
      method: "validateDate",
      validWhen: true,
      message: 'Ngày đi không hợp lệ',
    },
    {
      field: 'ngayDi',
      method: function(date, minDate, model) {
        if (DEBUG_MODE) return true;
        if (model.id) return true;
        return dayjs(date).toDate() > dayjs(minDate).toDate();
      },
      validWhen: true,
      message: 'Ngày đi phải lớn hơn ngày về gần nhất',
    },

    // Ngày về
    {
      field: 'ngayHetPhep',
      method: "validateDate",
      validWhen: true,
      message: 'Ngày về không hợp lệ',
    },
    {
        field: 'ngayHetPhep',
        method: function(date, model) {
          const dateDi = dayjs(model.ngayDi).toDate();
          if (date < dateDi) {
            return false;
          }
          return true;
        },
        validWhen: true,
        message: 'Ngày hết phép phải lớn hơn ngày đi',
    },

    // Ngày về thực tế
    {
      field: 'CuaKhauId',
      method: function(value) {
        if (!value) {
          return false;
        }
        return true;
      },
      validWhen: true,
      message: 'Cửa khẩu không được bỏ trống',
    },
    {
        field: 'trangThai',
        method: function(value, model) {
          if (!isEditTrangThai(model)) return null;
          if (!value) {
            return "Trạng thái không được bỏ trống";
          }
          return null;
        }
    },
    {
        field: 'ngayQuayLai',
        method: function(value, model) {
          if (!isEditTrangThai(model)) return null;
          if (model.trangthai === TrangThais[0]) {
              if (!value) {
                  return "Phải nhập ngày về thực tế";
              }
          }
          if (dayjs(model.ngayDi).toDate() >= dayjs(value).toDate()) {
              return "Ngày quay lại phải lớn hơn ngày đi";
          }
          return null;
        }
    },
    {
      field: 'ngayNghiViec',
      method: function(value, model) {
        if (!isEditTrangThai(model)) return null;
        if (model.trangThai === TrangThais[1]) {
            if (!value) {
                return "Phải nhập ngày nghỉ việc";
            }
            if (dayjs(model.ngayHetPhep).toDate() > dayjs(value).toDate()) {
                return "Ngày nghỉ việc phải lớn hơn ngày về dự kiến";
            }
        }
        return null;
      }
    }
  ];

export {isEditTrangThai, rules}