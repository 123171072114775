import ABSService from "./absService";
import requestHelper from "../utils/requestHelper";

class PhongBanService extends ABSService {
    constructor() {
        super("phongban");
    }

    getTree = async() => {
        const data = await requestHelper.get('/api/phongban/tree');
        return data;
    }

    getDsKhulienhop = async() => {
        const data = await requestHelper.get('/api/phongban?loai=Khu liên hợp');
        return data;
    }

    getDsPhongBan = async() => {
        const data = await requestHelper.get('/api/phongban?loai=Phòng ban');
        return data;
    }

    getPhongBanTreeForNV = async(nvId) => {
        const data = await requestHelper.get(`/api/nhanvien-phongban-tree/${nvId}`);
        return data;
    }

    sync = async() => {
        const data = await requestHelper.post(`/api/tree/sync`);
        return data;
    }
}

export default PhongBanService;