import './thongke.css'

import { Button, FormControl,Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { Component } from "react";
import ThongkeService from '../../services/thongkeService';
const thongKeService = new ThongkeService();

class TKNSVPPage extends Component {
  state = {
    ngayBatDau: (new Date()).setDate(new Date().getDate() - new Date().getDate() + 1),
    ngayKetThuc: new Date(),
    items: [],
    errors: {},
    isAll: false,
    //check: false,
  };

  getExcelThongKe = (ev) => {
    let isAll = this.state.isAll;
    let searchData = {
      ngayBatDau: dayjs(this.state.ngayBatDau).format("YYYY-MM-DD"),
      ngayKetThuc: dayjs(this.state.ngayKetThuc).format("YYYY-MM-DD"),
      isAll
    }
    thongKeService.getExcelThongKeNSVP(searchData).then(items => {
    });
  }
  render() {
    return (
      <Grid container spacing={2}>
        <Grid
          item
          xs={2}
          className={!this.state.errors.ngayBatDau ? "" : "custom-error-message"}
        >
          <FormControl fullWidth>
            <LocalizationProvider
              sx={{ innerWidth: "100%" }}
              dateAdapter={AdapterDayjs}
            >
              <DatePicker
                label="Ngày bắt đầu"
                inputFormat="DD/MM/YYYY"
                value={dayjs(this.state.ngayBatDau)}
                onChange={(ngayBatDau) => {this.setState({ngayBatDau: dayjs(ngayBatDau).toDate()})}}
                renderInput={(params) => <TextField {...params} />}
              />
              <div className="error-message">{this.state.errors.ngayBatDau}</div>
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={2}
          className={!this.state.errors.ngayBatDau ? "" : "custom-error-message"}
        >
          <FormControl fullWidth>
            <LocalizationProvider
              sx={{ innerWidth: "100%" }}
              dateAdapter={AdapterDayjs}
            >
              <DatePicker
                label="Ngày kết thúc"
                inputFormat="DD/MM/YYYY"
                value={dayjs(this.state.ngayKetThuc)}
                onChange={(ngayKetThuc) => {this.setState({ngayKetThuc: dayjs(ngayKetThuc).toDate()})}}
                renderInput={(params) => <TextField {...params} />}
              />
              <div className="error-message">{this.state.errors.ngayKetThuc}</div>
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
            <FormControl fullWidth>
              <FormControlLabel control={<Checkbox value={this.state.isAll} onChange={(ev) => {this.setState({isAll: ev.target.checked})}} />} label="Toàn bộ danh sách nhân viên" />
            </FormControl>
        </Grid>
        <Grid
          item
          xs={2}
        ></Grid>
        <Grid item xs={3}>
          <Button variant="contained" onClick={this.getExcelThongKe}>Tải Báo Cáo</Button>
        </Grid>
      </Grid>
    );
  }
}

export default TKNSVPPage; 